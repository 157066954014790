import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AuthContext } from 'App';
import { SignInData } from 'lib/model/client';
import { signIn } from 'lib/api/client/client';
import AlertMessage from 'components/utils/AlertMessage';

export default function ClientSignIn() {
  const history = useHistory()
  const location = useLocation<{ message?: string }>();
  const message = location.state?.message;
  const { setIsSignedIn, setCurrentClient } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(!!message);
  const [alertMessage, setAlertMessage] = useState<string>(message || '');
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const data: SignInData = {
      email: email,
      password: password
    }

    try {
      const res = await signIn(data)

      if (res.status === 200) {
        Cookies.set("_client_access_token", res.headers["access-token"])
        Cookies.set("_client_client", res.headers["client"])
        Cookies.set("_client_uid", res.headers["uid"])

        setIsSignedIn(true)
        setCurrentClient(res.data.data)
        // ログイン後はダッシュボードに遷移する
        history.push("/client")
      } else {
        setAlertMessage("メールアドレスまたはパスワードが違います");
        setAlertSeverity('error');
        setAlertMessageOpen(true)
      }
    } catch (err) {
      console.log(err)
      setAlertMessage("メールアドレスまたはパスワードが違います");
      setAlertSeverity('error');
      setAlertMessageOpen(true)
    }
  };

  useEffect(() => {
    if (location.state?.message) {
      setAlertMessage(location.state.message);
      setAlertSeverity('success');
      setAlertMessageOpen(true);
    }
  }, [location.state]);

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 7,
          paddingY: 7,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: "white",
        }}
      >
        <Typography component="h1" variant="h5" fontWeight="bold" >
          Call-ASP
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <AlertMessage
            open={alertMessageOpen}
            setOpen={setAlertMessageOpen}
            severity={alertSeverity}
            message={alertMessage}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
          >
            ログイン
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
