import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getAdvertises } from 'lib/api/admin/advertise';
import { Advertise } from 'lib/model/advertise';

interface AdminAdvertiseAggregateReportSearchFormProps {
  onSearch: (query: any) => void;
}

export const AdminAdvertiseAggregateReportSearchForm: React.FC<AdminAdvertiseAggregateReportSearchFormProps> = ({ onSearch }) => {
  const [advertiseIdEq, setAdvertiseIdEq] = useState('');
  const [advertiseNameStart, setAdvertiseNameStart] = useState('');
  const [advertises, setAdvertises] = useState<Advertise[]>([]);
  const [aggregateDateGteq, setAggregateDateGteq] = useState('');
  const [aggregateDateLteq, setAggregateDateLteq] = useState('');

  const handleAdvertiseSearch = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const res = await getAdvertises({ nameStart: advertiseNameStart })
      if (res.status === 200) {
        setAdvertises(res.data.advertises)
      } else {
        console.log("No Advertises")
      }
    } catch (err) {
      console.log(err)
    }
  };

  const selectAdvertise = (advertise: Advertise) => {
    setAdvertiseIdEq(`${advertise.id}`);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({
      advertiseIdEq,
      aggregateDateGteq,
      aggregateDateLteq
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>広告名で広告IDを検索できます</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="広告ID"
            variant="outlined"
            size="small"
            value={advertiseIdEq}
            onChange={(e) => setAdvertiseIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="広告名(前方一致)"
            variant="outlined"
            size="small"
            value={advertiseNameStart}
            onChange={(e) => setAdvertiseNameStart(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={handleAdvertiseSearch}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mb: '10px' }}>
        <Grid item>
          <Paper elevation={3} style={{ overflow: 'auto', maxHeight: '416px' }}>
            <List style={{ width: '100%' }}>
              {advertises.map((advertise: Advertise) => (
                <ListItem button key={advertise.id} onClick={() => selectAdvertise(advertise)}>
                  {advertise.name}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>集計期間を選択してください</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateGteq}
            onChange={(e) => setAggregateDateGteq(e.target.value)}
          />
        </Grid>

        <Grid item>~</Grid>

        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateLteq}
            onChange={(e) => setAggregateDateLteq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
