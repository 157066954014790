import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { Button, Paper, Grid, TextField, Typography } from '@mui/material';
import { AuthContext } from 'App';
import { Client } from 'lib/model/client';
import { updateCurrentClient, signOut } from 'lib/api/client/client';
import AlertMessage from 'components/utils/AlertMessage';

export default function ClientUserEditEmail() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { setIsSignedIn } = useContext(AuthContext);
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false);

  const userSignOut = () => {
    signOut()
    Cookies.remove("_client_access_token")
    Cookies.remove("_client_client")
    Cookies.remove("_client_uid")
    setIsSignedIn(false)
    history.push("/client/sign_in", { message: "メールアドレスを変更しました。再度ログインしてください" });
  };

  const history = useHistory()
  // 更新処理
  const onSubmit = async (data: Client) => {
    try {
      const res = await updateCurrentClient(data);
      if (res.status === 200) {
        console.log('Client password updated successfully');
        userSignOut()
      } else {
        console.log('Failed to update client password');
      }
    } catch (err) {
      console.log(err);
      setAlertMessageOpen(true)
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>メールアドレス変更</Typography>
      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <AlertMessage
                open={alertMessageOpen}
                setOpen={setAlertMessageOpen}
                severity="error"
                message="このメールアドレスはすでに使われています"
              />
              <AuthContext.Consumer>
                {(resource) => (
                  <TextField
                    id="email"
                    label="メールアドレス*"
                    fullWidth
                    {...register('email', {
                      required: "メールアドレスを入力してください",
                      pattern: {
                        value: /[\w+\-.]+@[a-z\d\-.]+\.[a-z][a-z]+/,
                        message: "メールアドレスの形式が正しくありません"
                      }
                    })}
                    error={errors.email ? true : false}
                    helperText={errors?.email?.message}
                    defaultValue={resource?.currentClient?.email}
                  />
                )}
              </AuthContext.Consumer>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '30px', marginLeft: '20px' }}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
