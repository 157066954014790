import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';
import { getAdvertises } from 'lib/api/admin/advertise';
import { Advertise } from 'lib/model/advertise';
import { DataGrid, GridColDef, GridRenderCellParams, jaJP, gridClasses } from '@mui/x-data-grid';
import { AdvertiseLandingPage } from 'lib/model/advertiseLandingPage';
import { AdminAdvertiseSearchForm } from 'components/search/AdminAdvertiseSearchForm';

export default function Advertises() {
  const [loading, setLoading] = useState<boolean>(true);
  const [advertises, setAdvertises] = useState<Advertise[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    handleGetAdvertises()
  }, [paginationModel, searchQuery]);

  const onSearch = (query = {}) => {
    setSearchQuery(query);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const history = useHistory();

  const handleEditButtonClick = (id: number) => {
    history.push(`/admin/advertises/${id}/edit`);
  };

  const handleDetailButtonClick = (id: number) => {
    history.push(`/admin/advertises/${id}`);
  };

  const handleGetAdvertises = async () => {
    setLoading(true);

    const paginatedQuery = {
      ...searchQuery,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    };

    try {
      const res = await getAdvertises(paginatedQuery)
      if (res.status === 200) {
        setAdvertises(res.data.advertises)
        setTotalRowCount(res.data.pagy.count);
      } else {
        console.log("No advertises")
      }
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "広告ID", flex: 0.5, minWidth: 100 },
    { field: "advertiseCode", headerName: "広告コード", flex: 0.5, minWidth: 200 },
    { field: "advertiseName", headerName: "広告名", flex: 0.5, minWidth: 200 },
    {
      field: "clientName",
      headerName: "クライアント名",
      flex: 0.5,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => (
        <a href={`/admin/clients/${params.row.advertise.client.id}`}>
          {params.row.advertise.client.name}
        </a>
      ),
    },
    {
      field: "clickUrl",
      headerName: "広告掲載用URL",
      flex: 1,
      minWidth: 600,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div>
          {
            params.row.advertise.advertiseLandingPages?.map((advertiseLandingPage: AdvertiseLandingPage, index: number) => (
              <>
                <a href={`${process.env.REACT_APP_API_URL}/click?acode=${params.row.advertise.advertiseCode}&lcode=${advertiseLandingPage.landingPageCode}`}>
                  {`${process.env.REACT_APP_API_URL}/click?acode=${params.row.advertise.advertiseCode}&lcode=${advertiseLandingPage.landingPageCode}`}
                </a>
                <br />
              </>
            ))
          }
        </div>
      )
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box display="flex" gap="10px">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleEditButtonClick(Number(params.row.advertise.id))}
          >
            編集
          </Button>

          <Button
            variant="contained"
            size="small"
            color="inherit"
            onClick={() => handleDetailButtonClick(Number(params.row.advertise.id))}
          >
            詳細
          </Button>
        </Box>
      ),
    },
  ]

  const rows =
    !loading && advertises.length > 0
      ? advertises.map((advertise: Advertise) => ({
        id: advertise.id,
        advertiseCode: advertise.advertiseCode,
        advertiseName: advertise.name,
        advertise: advertise,
      }))
      : [];

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ mb: '10px' }}>広告一覧</Typography>

        <Button component={Link} to="/admin/advertises/new" variant="contained" color="primary" sx={{ marginBottom: '10px' }}>
          新規登録
        </Button>
      </Box>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <AdminAdvertiseSearchForm onSearch={onSearch} />
      </Paper>

      <Paper elevation={3} sx={{ width: '100%' }}>
        <Box sx={{ height: rows.length > 0 ? 'auto' : '300px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRowCount}
            loading={loading}
            pageSizeOptions={[30, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            getRowHeight={() => 'auto'}
            sx={{ [`& .${gridClasses.cell}`]: { py: 1, } }}
          />
        </Box>
      </Paper>
    </>
  );
}
