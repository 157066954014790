import { useEffect, useState } from 'react';
import { Typography, Paper, Grid, Box } from '@mui/material';
import { DataGrid, GridColDef, jaJP, } from '@mui/x-data-grid';
import { getPhoneNumbers } from 'lib/api/admin/phoneNumber';
import { PhoneNumber } from 'lib/model/phoneNumber';
import { AdminPhoneNumberSearchForm } from 'components/search/AdminPhoneNumberSearchForm';
import { format } from 'date-fns';

export default function PhoneNumbers() {
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    handleGetPhoneNumbers()
  }, [paginationModel, searchQuery]);

  const onSearch = (query = {}) => {
    setSearchQuery(query);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const handleGetPhoneNumbers = async () => {
    setLoading(true);

    const paginatedQuery = {
      ...searchQuery,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    };

    try {
      const res = await getPhoneNumbers(paginatedQuery);
      if (res.status === 200) {
        setPhoneNumbers(res.data.phoneNumbers);
        setTotalRowCount(res.data.pagy.count);
      } else {
        console.log("No PhoneNumbers");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "電話番号ID", flex: 1, minWidth: 100 },
    { field: "clientId", headerName: "クライアントID", flex: 1, minWidth: 200 },
    { field: "clientName", headerName: "クライアント名", flex: 1, minWidth: 200 },
    { field: "advertiseId", headerName: "広告ID", flex: 1, minWidth: 200 },
    { field: "advertiseName", headerName: "広告名", flex: 1, minWidth: 200 },
    { field: "mediumId", headerName: "メディアID", flex: 1, minWidth: 200 },
    { field: "mediumName", headerName: "メディア名", flex: 1, minWidth: 200 },
    { field: "callaId", headerName: "コールトラッキングID", flex: 1, minWidth: 200 },
    { field: "phoneNumber", headerName: "転送元電話番号", flex: 1, minWidth: 200 },
    { field: "acode", headerName: "広告コード", flex: 1, minWidth: 150 },
    { field: "lcode", headerName: "LPコード", flex: 1, minWidth: 150 },
    { field: "mcode", headerName: "メディアコード", flex: 1, minWidth: 150 },
    { field: "displayTime", headerName: "電話番号表示時刻", flex: 1, minWidth: 200 },
    { field: "userAgent", headerName: "ユーザエージェント", flex: 1, minWidth: 150 },
    { field: "referer", headerName: "リファラ情報", flex: 1, minWidth: 150 },
    { field: "remoteIp", headerName: "IPアドレス", flex: 1, minWidth: 150 },
    { field: "displaySourceUrl", headerName: "転送電話番号表示URL", flex: 1, minWidth: 150 },
    { field: "mid1", headerName: "mid1", flex: 1, minWidth: 150 },
    { field: "mid2", headerName: "mid2", flex: 1, minWidth: 150 },
    { field: "mid3", headerName: "mid3", flex: 1, minWidth: 150 },
    { field: "mid4", headerName: "mid4", flex: 1, minWidth: 150 },
    { field: "mid5", headerName: "mid5", flex: 1, minWidth: 150 },
    { field: "gclid", headerName: "gclid", flex: 1, minWidth: 150 },
    { field: "yclid", headerName: "yclid", flex: 1, minWidth: 150 },
  ];

  const rows =
    !loading && phoneNumbers.length > 0
      ? phoneNumbers.map((phoneNumber: PhoneNumber) => ({
        id: phoneNumber.id,
        clientId: phoneNumber?.client?.id,
        clientName: phoneNumber?.client?.name,
        advertiseId: phoneNumber?.advertise?.id,
        advertiseName: phoneNumber?.advertise?.name,
        mediumId: phoneNumber?.medium?.id,
        mediumName: phoneNumber?.medium?.name,
        callaId: phoneNumber.callaId,
        phoneNumber: phoneNumber.phoneNumber,
        acode: phoneNumber.acode,
        lcode: phoneNumber.lcode,
        mcode: phoneNumber.mcode,
        displayTime: format(new Date(Number(phoneNumber.displayTime) * 1000), 'yyyy-MM-dd HH:mm:ss'),
        userAgent: phoneNumber.userAgent,
        referer: phoneNumber.referer,
        remoteIp: phoneNumber.remoteIp,
        displaySourceUrl: phoneNumber.displaySourceUrl,
        mid1: phoneNumber.mid1,
        mid2: phoneNumber.mid2,
        mid3: phoneNumber.mid3,
        mid4: phoneNumber.mid4,
        mid5: phoneNumber.mid5,
        gclid: phoneNumber.gclid,
        yclid: phoneNumber.yclid,
      }))
      : [];

  return (
    <>
      <Typography variant="h4" gutterBottom>電話番号表示一覧</Typography>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <AdminPhoneNumberSearchForm onSearch={onSearch} />
      </Paper >

      <Paper elevation={3} sx={{ width: '100%' }}>
        <Box p={2}>
          <Typography variant="subtitle1">
            電話番号表示回数: {totalRowCount}
          </Typography>
        </Box>

        <Box sx={{ height: rows.length > 0 ? 'auto' : '300px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRowCount}
            loading={loading}
            pageSizeOptions={[30, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Paper >
    </>
  );
}