import { useHistory } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import WebIcon from '@mui/icons-material/Web';
import ArticleIcon from '@mui/icons-material/Article';
import PaymentsIcon from '@mui/icons-material/Payments';

const MenuListItems = () => {
  const history = useHistory();

  return (
    <>
      <ListSubheader component="div">
        管理
      </ListSubheader>

      <ListItemButton onClick={() => history.push("/client/advertises")}>
        <ListItemIcon>
          <WebIcon />
        </ListItemIcon>
        <ListItemText primary="広告" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/client/media")}>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="メディア" />
      </ListItemButton>

      <ListSubheader component="div">
        計測
      </ListSubheader>

      <ListItemButton onClick={() => history.push("/client/achievements")}>
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="成果" />
      </ListItemButton>
    </>
  )
}

const Sidebar = () => {
  return (
    <List component="nav">
      <MenuListItems />
    </List>
  );
};

export default Sidebar;
