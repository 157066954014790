import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import Cookies from "js-cookie";
import { Admin, SignInData } from "lib/model/admin";

const options = {
  ignoreHeaders: true,
};

const admin: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

admin.interceptors.request.use(setHeaders);

export const signIn = (data: SignInData) => {
  return admin.post("/api/admin/sign_in", data);
};

export const signOut = () => {
  return admin.delete("/api/admin/sign_out");
};

export const getCurrentAdmin = () => {
  return admin.get("/api/admin/validate_token");
};

// ユーザ情報変更
export const updateAdmin = (data: Admin) => {
  return admin.put("/api/admin", data);
};
