import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import Cookies from "js-cookie";
import { Client, SignInData } from "lib/model/client";

const options = {
  ignoreHeaders: true,
};

const client: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

client.interceptors.request.use(setHeaders);

export const getClients = async (query: {
  idEq?: string,
  nameStart?: string
  emailStart?: string
  page?: number
  pageSize?: number
} = {}) => {
  return client.get("/api/admin/clients", {
    params: {
      "q[id_eq]": query.idEq || null,
      "q[name_start]": query.nameStart || null,
      "q[email_start]": query.emailStart || null,
      page: query.page || 1,
      page_size: query.pageSize || 30,
    }
  });
};

export const getClient = (id: string) => {
  return client.get(`/api/admin/clients/${id}`);
};

export const createClient = (data: Client) => {
  return client.post("/api/admin/clients", data);
};

export const updateClient = (data: Client) => {
  return client.put(`/api/admin/clients/${data.id}`, data);
};
