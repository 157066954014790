import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Paper, Grid, Divider } from '@mui/material';
import { Achievement } from 'lib/model/achievement';
import { getAchievement } from 'lib/api/client/achievement';
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";

export default function AchievementsShow() {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();
  const [achievement, setAchievement] = useState<Achievement | null>(null);
  const history = useHistory();

  useEffect(() => {
    async function fetchAchievement() {
      try {
        const res = await getAchievement(parseInt(id, 10));
        if (res.status === 200) {
          setAchievement(res.data);
        } else {
          console.log('Could not fetch achievement details');
          history.push('/client/achievements');
        }
      } catch (err) {
        console.log(err);
        history.push('/client/achievements');
      }
    }

    fetchAchievement();
  }, [id]);

  return (
    <>
      <Typography variant="h4">成果詳細</Typography>

      {achievement ? (
        <Paper elevation={3} style={{ padding: '16px', overflow: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                ID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.id}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                広告ID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.advertise.id}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                広告名:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.advertise.name}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                広告LPID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.advertiseLandingPage?.id}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                広告LP名:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.advertiseLandingPage?.name}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                メディアID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.medium?.id || ''}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                メディア名:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.medium?.name || ''}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                ノートUUID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.callNoteUuid}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                発信番号:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.originatingNumber}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                転送番号:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.forwardingNumber}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                宛先番号:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.destinationNumber}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                電話番号表示時刻:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {achievement.phoneNumberDisplayTime && format(Date.parse(achievement.phoneNumberDisplayTime), 'yyyy/MM/dd HH:mm:ss')}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                通話発信時刻:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {achievement.callStartTime ? format(new Date(achievement.callStartTime), 'yyyy/MM/dd HH:mm:ss') : ''}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">通話応答時刻:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {achievement.callAnswerTime ? format(new Date(achievement.callAnswerTime), 'yyyy/MM/dd HH:mm:ss') : ''}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                通話終了時刻:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {achievement.callEndTime ? format(new Date(achievement.callEndTime), 'yyyy/MM/dd HH:mm:ss') : ''}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                通話時間(秒):
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.callDuration}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                MID1:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.mid1}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                MID2:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.mid2}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                MID3:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.mid3}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                MID4:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.mid4}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                MID5:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.mid5}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                gclid:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.gclid}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                yclid:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.yclid}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                転送電話番号表示URL:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.displaySourceUrl}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                ユーザーエージェント:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.userAgent}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                デバイス:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.device}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                端末種別:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.phoneType}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                リファラ:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.referer}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                パラメータ:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{achievement.parameters}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                ステータス:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {achievement.status === 'valid' ? '有効' : '無効'}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                キックバックステータス:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{t(`kickbackStatus.${achievement.kickbackStatus}`)}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                成果発生メールステータス:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{t(`notificationStatus.${achievement.notificationStatus}`)}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                作成時刻:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {format(Date.parse(achievement.createdAt), 'yyyy/MM/dd HH:mm:ss')}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                更新時刻:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {format(Date.parse(achievement.updatedAt), 'yyyy/MM/dd HH:mm:ss')}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Typography variant="body1">データを読み込んでいます...</Typography>
      )}
    </>
  );
};
