import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import Cookies from "js-cookie";
import { Client, SignInData } from "lib/model/client";

const options = {
  ignoreHeaders: true,
};

const client: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_client_access_token");
  config.headers["client"] = Cookies.get("_client_client");
  config.headers["uid"] = Cookies.get("_client_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

client.interceptors.request.use(setHeaders);

export const signIn = (data: SignInData) => {
  return client.post("/api/client/sign_in", data);
};

export const signOut = () => {
  return client.delete("/api/client/sign_out");
};

export const getCurrentClient = () => {
  return client.get("/api/client/validate_token");
};

export const updateCurrentClient = (data: Client) => {
  return client.put("/api/client", data);
};
