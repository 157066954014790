import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AppBar, Box, Button, Divider, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AuthContext } from 'App';
import { signOut } from 'lib/api/client/client';

type HeaderProps = {
  toggleDrawer: VoidFunction;
};

export default function Header({ toggleDrawer }: HeaderProps) {
  const history = useHistory();
  const { setIsSignedIn } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const response = await signOut();

      if (response.data.success === true) {
        Cookies.remove('_client_access_token');
        Cookies.remove('_client_client');
        Cookies.remove('_client_uid');
        setIsSignedIn(false);
        history.push('/client/sign_in');
      } else {
        console.log('ログアウトに失敗しました');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const redirectEditMail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    handleClose();
    history.push('/client/user/email/edit');
  };

  const redirectEditPass = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    handleClose();
    history.push('/client/user/password/edit');
  };

  return (
    <AppBar position="absolute">
      <Toolbar>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{
            marginRight: '59px',
            marginLeft: '35px',
          }}
        >
          Call-ASP
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{ marginLeft: '20px' }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            transitionDuration={400}
            onClick={handleClose}
          >
            <MenuItem onClick={redirectEditMail}>メールアドレス変更</MenuItem>
            <MenuItem onClick={redirectEditPass}>パスワード変更</MenuItem>
          </Menu>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Button color="inherit" onClick={handleMenu}>アカウント</Button>
            <Button color="inherit" onClick={handleSignOut}>ログアウト</Button>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
