import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import applyCaseMiddleware from "axios-case-converter";

const options = {
  ignoreHeaders: true,
};

const aggregateReport: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

aggregateReport.interceptors.request.use(setHeaders);

export const getClientAggregateReports = async (query: {
  clientIdEq?: string,
  aggregateDateGteq?: string,
  aggregateDateLteq?: string,
  page?: number,
  pageSize?: number
}) => {
  return aggregateReport.get("/api/admin/client_aggregate_reports", {
    params: {
      "q[client_id_eq]": query.clientIdEq || null,
      "q[aggregate_date_gteq]": query.aggregateDateGteq || null,
      "q[aggregate_date_lteq]": query.aggregateDateLteq || null,
      "page": query.page || 1,
      "page_size": query.pageSize || 30,
    }
  });
}

export const getAdvertiseAggregateReports = async (query: {
  advertiseIdEq?: string,
  aggregateDateGteq?: string,
  aggregateDateLteq?: string,
  page?: number,
  pageSize?: number
}) => {
  return aggregateReport.get("/api/admin/advertise_aggregate_reports", {
    params: {
      "q[advertise_id_eq]": query.advertiseIdEq || null,
      "q[aggregate_date_gteq]": query.aggregateDateGteq || null,
      "q[aggregate_date_lteq]": query.aggregateDateLteq || null,
      "page": query.page || 1,
      "page_size": query.pageSize || 30,
    }
  });
}

export const getMediumAggregateReports = async (query: {
  mediumIdEq?: string,
  aggregateDateGteq?: string,
  aggregateDateLteq?: string,
  page?: number,
  pageSize?: number
}) => {
  return aggregateReport.get("/api/admin/medium_aggregate_reports", {
    params: {
      "q[medium_id_eq]": query.mediumIdEq || null,
      "q[aggregate_date_gteq]": query.aggregateDateGteq || null,
      "q[aggregate_date_lteq]": query.aggregateDateLteq || null,
      "page": query.page || 1,
      "page_size": query.pageSize || 30,
    }
  });
}

export const getDateAggregateReports = async (query: {
  aggregateDateGteq?: string,
  aggregateDateLteq?: string,
  page?: number,
  pageSize?: number
}) => {
  return aggregateReport.get("/api/admin/date_aggregate_reports", {
    params: {
      "q[aggregate_date_gteq]": query.aggregateDateGteq || null,
      "q[aggregate_date_lteq]": query.aggregateDateLteq || null,
      "page": query.page || 1,
      "page_size": query.pageSize || 30,
    }
  });
}
