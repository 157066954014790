import { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid, Paper, TextField, Typography, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent, FormHelperText } from '@mui/material';
import { getClient, updateClient } from 'lib/api/admin/client';
import { Client } from 'lib/model/client';

export default function EditClient() {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [selectedAchievementNotification, setSelectedAchievementNotification] = useState<string>('');
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    handleGetClient();
  }, []);

  const handleGetClient = async () => {
    try {
      const res = await getClient(id);
      if (res.status === 200) {
        setSelectedAchievementNotification(res.data.client.achievementNotification);
        reset(res.data.client);
      } else {
        console.log('No client found');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data: Client) => {
    data.password = data.password || undefined;
    data.passwordConfirmation = data.passwordConfirmation || undefined;

    try {
      const res = await updateClient(data);
      if (res.status === 200) {
        console.log('Client updated successfully');
        history.push('/admin/clients');
      } else {
        console.log('Failed to update client');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>
        クライアント編集
      </Typography>
      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="名前"
                fullWidth
                {...register('name', { required: "名前を入力してください" })}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="メール"
                fullWidth
                {...register('email', {
                  required: "メールを入力してください",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "メールの形式が正しくありません"
                  }
                })}
                error={errors.email ? true : false}
                helperText={errors?.email?.message}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="パスワード"
                fullWidth
                type="password"
                {...register('password', {
                  minLength: {
                    value: 8,
                    message: "パスワードは8文字以上で入力してください"
                  },
                })}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                error={errors.password ? true : false}
                helperText={errors?.password?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="パスワード確認"
                fullWidth
                type="password"
                {...register('password_confirmation', {
                  minLength: {
                    value: 8,
                    message: "パスワードは8文字以上で入力してください"
                  },
                })}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                error={errors.password_confirmation ? true : false}
                helperText={errors?.password_confirmation?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="成果発生メール"
                select
                fullWidth
                value={selectedAchievementNotification}
                {...register('achievementNotification', {
                  required: "成果発生メールを選択してください",
                  onChange: (e: any) => {
                    setSelectedAchievementNotification(e.target.value);
                  }
                })}
                error={errors.achievementNotification ? true : false}
                helperText={errors?.achievementNotification?.message}
              >
                <MenuItem value="disabled">通知しない</MenuItem>
                <MenuItem value="enabled">通知する</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={2}>
              <Button
                component={Link}
                to="/admin/clients"
                variant="outlined"
                href="#outlined-buttons"
                sx={{ marginTop: '30px' }}
              >
                戻る
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '30px', marginLeft: '20px' }}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper >
    </>
  );
}