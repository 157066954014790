import React, { useState } from 'react';
import { Paper, TextField, Button, Grid, List, ListItem } from '@mui/material';
import { getClients } from 'lib/api/admin/client';
import { Client } from 'lib/model/client';

interface AdminPhoneNumberSearchFormProps {
  onSearch: (query: any) => void;
}

export const AdminPhoneNumberSearchForm: React.FC<AdminPhoneNumberSearchFormProps> = ({ onSearch }) => {
  const [idEq, setIdEq] = useState('');
  const [clientIdEq, setClientIdEq] = useState('');
  const [callaIdEq, setCallaIdEq] = useState('');
  const [phoneNumberEq, setPhoneNumberEq] = useState('');
  const [remoteIpEq, setRemoteIpEq] = useState('');
  const [displayTimeGteq, setDisplayTimeGteq] = useState('');
  const [displayTimeLteq, setDisplayTimeLteq] = useState('');
  const [clientNameStart, setClientNameStart] = useState('');
  const [clients, setClients] = useState<Client[]>([]);

  const handleClientSearch = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const res = await getClients({ nameStart: clientNameStart })
      if (res.status === 200) {
        setClients(res.data.clients)
      } else {
        console.log("No clients")
      }
    } catch (err) {
      console.log(err)
    }
  };

  const selectClient = (client: Client) => {
    setClientIdEq(`${client.id}`);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({
      idEq,
      clientIdEq,
      callaIdEq,
      phoneNumberEq,
      remoteIpEq,
      displayTimeGteq,
      displayTimeLteq
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>クライアント名でクライアントIDを検索できます</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="クライアントID"
            variant="outlined"
            size="small"
            value={clientIdEq}
            onChange={(e) => setClientIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="クライアント名(前方一致)"
            variant="outlined"
            size="small"
            value={clientNameStart}
            onChange={(e) => setClientNameStart(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={handleClientSearch}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mb: '10px' }}>
        <Grid item>
          <Paper elevation={3} style={{ overflow: 'auto', maxHeight: '416px' }}>
            <List style={{ width: '100%' }}>
              {clients.map((client: Client) => (
                <ListItem button key={client.id} onClick={() => selectClient(client)}>
                  {client.name}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>電話番号表示時刻の期間を選択してください</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={displayTimeGteq}
            onChange={(e) => setDisplayTimeGteq(e.target.value)}
          />
        </Grid>

        <Grid item>~</Grid>

        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={displayTimeLteq}
            onChange={(e) => setDisplayTimeLteq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>値で絞り込み検索ができます</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="電話番号ID"
            variant="outlined"
            size="small"
            value={idEq}
            onChange={(e) => setIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="calla_id"
            variant="outlined"
            size="small"
            value={callaIdEq}
            onChange={(e) => setCallaIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="電話番号"
            variant="outlined"
            size="small"
            value={phoneNumberEq}
            onChange={(e) => setPhoneNumberEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="IPアドレス"
            variant="outlined"
            size="small"
            value={remoteIpEq}
            onChange={(e) => setRemoteIpEq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form >
  );
};
