import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';

interface AdminMediumSearchFormProps {
  onSearch: (query: any) => void;
}

export const AdminMediumSearchForm: React.FC<AdminMediumSearchFormProps> = ({ onSearch }) => {
  const [idEq, setIdEq] = useState('');
  const [mediumCodeEq, setMediumCodeEq] = useState('');
  const [nameStart, setNameStart] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({
      idEq,
      mediumCodeEq,
      nameStart
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="メディアID"
            variant="outlined"
            size="small"
            value={idEq}
            onChange={(e) => setIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="メディアコード"
            variant="outlined"
            size="small"
            value={mediumCodeEq}
            onChange={(e) => setMediumCodeEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="メディア名(前方一致)"
            variant="outlined"
            size="small"
            value={nameStart}
            onChange={(e) => setNameStart(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
