import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Paper, Typography, Divider, Grid, Button, Card, CardActionArea, CardMedia } from '@mui/material';
import { format } from 'date-fns';
import { getAdvertise } from 'lib/api/client/advertise';
import { Advertise } from 'lib/model/advertise';

interface Params {
  id: string;
}

export default function AdvertisesDetail() {
  const { id } = useParams<Params>();
  const [advertise, setAdvertise] = useState<Advertise | undefined>();
  const history = useHistory();

  useEffect(() => {
    handleGetAdvertise();
  }, []);

  const handleGetAdvertise = async () => {
    try {
      const res = await getAdvertise(id);
      if (res.status === 200) {
        setAdvertise(res.data);
      } else {
        console.log('No advertise');
        history.push('/client/advertises');
      }
    } catch (err) {
      console.log(err);
      history.push('/client/advertises');
    }
  };

  if (!advertise) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4">広告詳細</Typography>
        <Button
          component={Link}
          to="/client/advertises"
          variant="outlined"
          href="#outlined-buttons"
        >
          戻る
        </Button>
      </Box>

      <Paper
        sx={{
          padding: (theme) => theme.spacing(3),
          overflow: 'hidden',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告ID:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{advertise.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告コード:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{advertise.advertiseCode}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告名:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{advertise.name}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>成果発生条件（秒）:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{advertise.cvTime}</Typography>
          </Grid>
        </Grid>

        {advertise.advertiseLandingPages?.map((advertiseLandingPage, index) => (
          <>
            <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

            <Grid container spacing={2} key={index}>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>広告LPコード {index + 1}:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" gutterBottom>{advertiseLandingPage.landingPageCode}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>広告LP名 {index + 1}:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" gutterBottom>{advertiseLandingPage.name}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>PC用URL {index + 1}:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" gutterBottom>{advertiseLandingPage.pcUrl}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>SP用URL {index + 1}:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" gutterBottom>{advertiseLandingPage.spUrl}</Typography>
              </Grid>
            </Grid>
          </>
        ))}

        {advertise.transferPhoneNumbers?.map((attribute, index) => (
          <>
            <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

            <Grid container spacing={2} key={index}>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>計測番号 {index + 1}:</Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography variant="h6" gutterBottom>{attribute.phoneNumber}</Typography>
              </Grid>
            </Grid>
          </>
        ))}

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>ポップアップ:</Typography>
          </Grid>

          <Grid item xs={8}>
            {advertise.clickImageUrl && (
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height=""
                    image={advertise.clickImageUrl}
                    alt="ポップアップ画像"
                    style={{
                      height: 300,
                      width: 'auto',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      margin: 'auto',
                    }}
                  />
                </CardActionArea>
              </Card>
            )}
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>作成時刻:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              {format(Date.parse(advertise.createdAt), "yyyy/MM/dd HH:mm:ss")}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>更新時刻:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              {format(Date.parse(advertise.updatedAt), "yyyy/MM/dd HH:mm:ss")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
