import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Paper, Typography, Divider, Grid, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { format } from 'date-fns';

import { getMedium } from 'lib/api/client/medium';
import { Medium, mediumStatusDisplayText } from 'lib/model/medium';
import { connectionPlatformType, adsAccessToken } from 'lib/model/medium';

import copy from 'copy-to-clipboard';

interface Params {
  id: string;
}

export default function MediaDetail() {
  const { id } = useParams<Params>();
  const [medium, setMedium] = useState<Medium | null>(null);

  useEffect(() => {
    const fetchMedium = async () => {
      try {
        const res = await getMedium(id);
        if (res.status === 200) {
          setMedium(res.data.medium);
        } else {
          console.log('No Medium Found');
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchMedium();
  }, [id]);

  const copyAccessTokenToClipboard = (medium: Medium) => {
    const token = adsAccessToken(medium);
    copy(token);
  };

  if (!medium) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4">メディア詳細</Typography>
        <Button
          component={Link}
          to="/client/media"
          variant="outlined"
          href="#outlined-buttons"
        >
          戻る
        </Button>
      </Box>
      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          overflow: 'hidden',
        }}
      >

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メディアID:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{medium.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メディアコード:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{medium.mediumCode}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メディア名:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{medium.name}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メディアURL:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{medium.siteUrl}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>ステータス:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{mediumStatusDisplayText(medium)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>媒体連携:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" className="text" gutterBottom>{connectionPlatformType(medium)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>アクセストークン:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" className="text" gutterBottom>
              {adsAccessToken(medium).length > 30 ? `${adsAccessToken(medium).substring(0, 30)}...` : adsAccessToken(medium)}

              <IconButton onClick={() => copyAccessTokenToClipboard(medium)} aria-label="copy">
                <ContentCopyIcon />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>作成時刻:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{format(Date.parse(medium.createdAt), "yyyy/MM/dd HH:mm:ss")}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>更新時刻:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{format(Date.parse(medium.updatedAt), "yyyy/MM/dd HH:mm:ss")}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
