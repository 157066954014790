import { useEffect, useState } from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { DataGrid, GridColDef, jaJP, } from '@mui/x-data-grid';
import { getMediumAggregateReports } from 'lib/api/admin/aggregateReport';
import { MediumAggregateReport } from 'lib/model/mediumAggregateReport';
import { AdminMediumAggregateReportSearchForm } from 'components/search/AdminMediumAggregateReportSearchForm';

export default function MediumAggregateReports() {
  const [loading, setLoading] = useState<boolean>(false);
  const [mediumAggregateReports, setMediumAggregateReports] = useState<MediumAggregateReport[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    handleGetAggregateReports()
  }, [paginationModel, searchQuery]);

  const onSearch = (query = {}) => {
    setSearchQuery(query);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const handleGetAggregateReports = async () => {
    setLoading(true);

    const paginatedQuery = {
      ...searchQuery,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    };

    try {
      const res = await getMediumAggregateReports(paginatedQuery);
      if (res.status === 200) {
        setMediumAggregateReports(res.data.mediumAggregateReports);
        setTotalRowCount(res.data.pagy.count);
      } else {
        console.log("No Medium Aggregate Reports");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const columns: GridColDef[] = [
    { field: "mediumId", headerName: "メディアID", flex: 1, minWidth: 200 },
    { field: "mediumName", headerName: "メディア名", flex: 1, minWidth: 200 },
    { field: "phoneNumberDisplayCount", headerName: "電話番号表示回数", flex: 1, minWidth: 200 },
    { field: "totalCvCount", headerName: "総CV数", flex: 1, minWidth: 200 },
    { field: "validCvCount", headerName: "有効CV数", flex: 1, minWidth: 200 },
    { field: "kickbackSuccessCvCount", headerName: "キックバック成功CV数", flex: 1, minWidth: 200 },
  ];

  const rows =
    !loading && mediumAggregateReports?.length > 0
      ? mediumAggregateReports.map((mediumAggregateReport: MediumAggregateReport, index: number) => ({
        id: index,
        mediumId: mediumAggregateReport.mediumId,
        mediumName: mediumAggregateReport.mediumName,
        phoneNumberDisplayCount: mediumAggregateReport.phoneNumberDisplayCount,
        totalCvCount: mediumAggregateReport.totalCvCount,
        validCvCount: mediumAggregateReport.validCvCount,
        kickbackSuccessCvCount: mediumAggregateReport.kickbackSuccessCvCount,
      }))
      : [];

  return (
    <>
      <Typography variant="h4" gutterBottom>メディア別レポート一覧</Typography>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <AdminMediumAggregateReportSearchForm onSearch={onSearch} />
      </Paper>

      <Paper elevation={3} sx={{ width: '100%' }}>
        <Box sx={{ height: rows.length > 0 ? 'auto' : '300px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRowCount}
            loading={loading}
            pageSizeOptions={[30, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Paper>
    </>
  );
}