import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Paper, Typography, Box, } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport, GridRenderCellParams, jaJP, } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import { getAchievements } from 'lib/api/admin/achievement';
import { Achievement } from 'lib/model/achievement';
import { AdminAchievementSearchForm } from 'components/search/AdminAchievementSearchForm'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarExport
        csvOptions={{ utf8WithBom: true, fileName: "achievements_" + format(Date.parse(Date()), "yyyyMMddHHmmssdd") }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  )
}

export default function Achievements() {
  const [t] = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    handleGetAchievements()
  }, [paginationModel, searchQuery]);

  const onSearch = (query = {}) => {
    setSearchQuery(query);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const history = useHistory();

  const handleDetailsButtonClick = (id: number) => {
    history.push(`/admin/achievements/${id}`);
  };

  const handleGetAchievements = async () => {
    setLoading(true);

    const paginatedQuery = {
      ...searchQuery,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    };

    try {
      const res = await getAchievements(paginatedQuery);
      if (res.status === 200) {
        setAchievements(res.data.achievements);
        setTotalRowCount(res.data.pagy.count);
      } else {
        console.log("No achievements");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "成果ID", flex: 1, minWidth: 100 },
    { field: "clientId", headerName: "クライアントID", editable: true, flex: 1, minWidth: 150 },
    { field: "clientName", headerName: "クライアント名", editable: true, flex: 1, minWidth: 150 },
    { field: "advertiseId", headerName: "広告ID", editable: true, flex: 1, minWidth: 150 },
    { field: "advertiseName", headerName: "広告名", editable: true, flex: 1, minWidth: 150 },
    { field: "advertiseLandingPageId", headerName: "広告LPID", editable: true, flex: 1, minWidth: 150 },
    { field: "advertiseLandingPageName", headerName: "広告LP名", editable: true, flex: 1, minWidth: 150 },
    { field: "mediumID", headerName: "メディアID", editable: true, flex: 1, minWidth: 150 },
    { field: "mediumName", headerName: "メディア名", editable: true, flex: 1, minWidth: 150 },
    { field: "originatingNumber", headerName: "発信者電話番号", editable: true, flex: 1, minWidth: 150 },
    { field: "forwardingNumber", headerName: "転送元電話番号", editable: true, flex: 1, minWidth: 150 },
    { field: "phoneNumberDisplayTime", headerName: "電話番号表示時刻", editable: true, flex: 1, minWidth: 200 },
    { field: "callStartTime", headerName: "通話発信時刻", editable: true, flex: 1, minWidth: 200 },
    { field: "callAnswerTime", headerName: "通話応答時刻", editable: true, flex: 1, minWidth: 200 },
    { field: "callEndTime", headerName: "通話終了時刻", editable: true, flex: 1, minWidth: 200 },
    { field: "callDuration", headerName: "通話時間(秒)", editable: true, flex: 1, minWidth: 200 },
    { field: "callNoteUuid", headerName: "コールノートUUID", editable: true, flex: 1, minWidth: 150 },
    { field: "displaySourceUrl", headerName: "転送電話番号表示URL", editable: true, flex: 1, minWidth: 150 },
    { field: "status", headerName: "ステータス", editable: true, flex: 1, minWidth: 150 },
    { field: "kickbackStatus", headerName: "キックバックステータス", editable: true, flex: 1, minWidth: 150 },
    { field: "notificationStatus", headerName: "成果発生メールステータス", editable: true, flex: 1, minWidth: 150 },
    {
      field: "show",
      headerName: "詳細",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 100,
      flex: 1,
      disableExport: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <Button
            variant="contained"
            size="small"
            color="inherit"
            onClick={() => handleDetailsButtonClick(Number(params.id))}
          >
            詳細
          </Button>
        </>
      ),
    },
  ];

  const rows =
    !loading && achievements.length > 0
      ? achievements.map((achievement: Achievement) => ({
        id: achievement.id,
        clientId: achievement.client.id,
        clientName: achievement.client.name,
        advertiseId: achievement.advertise.id,
        advertiseName: achievement.advertise.name,
        advertiseLandingPageId: achievement.advertiseLandingPage?.id,
        advertiseLandingPageName: achievement.advertiseLandingPage?.name,
        mediumID: achievement.medium?.id,
        mediumName: achievement.medium?.name,
        originatingNumber: achievement.originatingNumber,
        forwardingNumber: achievement.forwardingNumber,
        phoneNumberDisplayTime: achievement.phoneNumberDisplayTime && format(Date.parse(achievement.phoneNumberDisplayTime), "yyyy/MM/dd HH:mm:ss"),
        callStartTime: achievement.callStartTime && format(Date.parse(achievement.callStartTime), "yyyy/MM/dd HH:mm:ss"),
        callAnswerTime: achievement.callAnswerTime && format(Date.parse(achievement.callAnswerTime), "yyyy/MM/dd HH:mm:ss"),
        callEndTime: achievement.callEndTime && format(Date.parse(achievement.callEndTime), "yyyy/MM/dd HH:mm:ss"),
        callDuration: achievement.callDuration,
        callNoteUuid: achievement.callNoteUuid,
        displaySourceUrl: achievement.displaySourceUrl,
        status: achievement.status === 'valid' ? '有効' : '無効',
        kickbackStatus: t(`kickbackStatus.${achievement.kickbackStatus}`),
        notificationStatus: t(`notificationStatus.${achievement.notificationStatus}`),
      }))
      : [];

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>成果一覧</Typography>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <AdminAchievementSearchForm onSearch={onSearch} />
      </Paper >

      <Paper elevation={3} sx={{ width: '100%' }}>
        <Box sx={{ height: rows.length > 0 ? 'auto' : '300px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRowCount}
            loading={loading}
            pageSizeOptions={[30, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: CustomToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Paper >
    </>
  );
}
