import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';

interface ClientAchievementSearchFormProps {
  onSearch: (query: any) => void;
}

export const ClientAchievementSearchForm: React.FC<ClientAchievementSearchFormProps> = ({ onSearch }) => {
  const [idEq, setIdEq] = useState('');
  const [advertiseIdEq, setAdvertiseIdEq] = useState('');
  const [advertiseNameStart, setAdvertiseNameStart] = useState('');
  const [callStartTimeGteq, setCallStartTimeGteq] = useState('');
  const [callStartTimeLteq, setCallStartTimeLteq] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({ idEq, callStartTimeGteq, callStartTimeLteq });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="成果ID"
            variant="outlined"
            size="small"
            value={idEq}
            onChange={(e) => setIdEq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>通話発信時刻の期間を選択してください</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={callStartTimeGteq}
            onChange={(e) => setCallStartTimeGteq(e.target.value)}
          />
        </Grid>

        <Grid item>~</Grid>

        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={callStartTimeLteq}
            onChange={(e) => setCallStartTimeLteq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
