import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Paper, Typography, Divider, Grid } from '@mui/material';
import { format } from 'date-fns';

import { getClient } from 'lib/api/admin/client';
import { achievementNotificationDisplayText, Client } from 'lib/model/client';

interface Params {
  id: string;
}

export default function ClientDetail() {
  const { id } = useParams<Params>();
  const [client, setClient] = useState<Client | null>(null);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const res = await getClient(id);
        if (res.status === 200) {
          setClient(res.data.client);
        } else {
          console.log('No client found');
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchClient();
  }, [id]);

  if (!client) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4">クライアント詳細</Typography>

        <Button
          component={Link}
          to="/admin/clients"
          variant="outlined"
          href="#outlined-buttons"
        >
          戻る
        </Button>
      </Box>

      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          overflow: 'hidden',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>ID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{client.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>クライアント名:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{client.name}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メールアドレス:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{client.email}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>成果発生メール:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievementNotificationDisplayText(client.achievementNotification)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>作成時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{format(Date.parse(client.createdAt), "yyyy/MM/dd HH:mm:ss")}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>更新時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{format(Date.parse(client.updatedAt), "yyyy/MM/dd HH:mm:ss")}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}