import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import applyCaseMiddleware from "axios-case-converter";

const options = {
  ignoreHeaders: true,
};

const achievement: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

achievement.interceptors.request.use(setHeaders);

export const getAchievements = async (query: {
  idEq?: string,
  clientIdEq?: string,
  clientNameStart?: string,
  advertiseIdEq?: string,
  advertiseNameStart?: string,
  callStartTimeGteq?: string,
  callStartTimeLteq?: string,
  page?: number,
  pageSize?: number
}) => {
  return achievement.get("/api/admin/achievements", {
    params: {
      "q[id_eq]": query.idEq || null,
      "q[client_id_eq]": query.clientIdEq || null,
      "q[client_name_start]": query.clientNameStart || null,
      "q[advertise_id_eq]": query.advertiseIdEq || null,
      "q[advertise_name_start]": query.advertiseNameStart || null,
      "q[call_start_time_gteq]": query.callStartTimeGteq || null,
      "q[call_start_time_lteq_end_of_day]": query.callStartTimeLteq || null,
      "page": query.page || 1,
      "pageSize": query.pageSize || 30
    }
  });
};

export const getAchievement = (id: number) => {
  return achievement.get(`/api/admin/achievements/${id}`);
};
