import React, { useState, useContext } from 'react';
import { Box, Drawer, styled, Toolbar, createTheme, ThemeProvider } from '@mui/material';
import { AuthContext } from 'App';
import Header from 'components/layouts/admin/Header';
import Sidebar from 'components/layouts/admin/Sidebar';

// サイドバーのレイアウト
const SidebarDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    const drawerWidth = '250px';
    const collapsedDrawerWidth = theme.spacing(7);
    const transitionTiming = theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    });

    const drawerStyle = {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      boxSizing: 'border-box',
      transition: transitionTiming,
    };

    const collapsedStyle = {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: collapsedDrawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: collapsedDrawerWidth,
      },
    };

    return {
      marginTop: theme.spacing(9),
      '& .MuiDrawer-paper': {
        ...drawerStyle,
        ...(open ? {} : collapsedStyle),
      },
    };
  }
);

// 一覧画面のレイアウト
const MainComponent = styled('div')(({ theme }) => {
  const backgroundColor = theme.palette.mode === 'light'
    ? theme.palette.grey[100]
    : theme.palette.grey[900];

  return {
    backgroundColor,
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
    height: '100vh',
    padding: '40px 24px',
    borderRadius: '10px',
  };
});

const mdTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
    },
  },
});

export default function BaseLayout({ children }: { children: React.ReactElement }) {
  const { loading, isSignedIn } = useContext(AuthContext);
  const [open, setOpen] = useState(true);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  if (loading || !isSignedIn) {
    // ログイン画面を表示する場合
    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <MainComponent>
            {children}
          </MainComponent>
        </Box>
      </ThemeProvider>
    );
  }

  // ログイン後の画面を表示する場合
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Header toggleDrawer={handleToggleDrawer} />
        <SidebarDrawer variant="permanent" open={open}>
          <Sidebar />
        </SidebarDrawer>
        <MainComponent>
          <Toolbar />
          {children}
        </MainComponent>
      </Box>
    </ThemeProvider>
  );
}
