export interface SignInData {
  email: string
  password: string
}

export interface Client {
  id: number
  name: string
  uid: string
  provider: string
  email: string
  password: string | undefined
  passwordConfirmation: string | undefined
  achievementNotification: string
  createdAt: string
  updatedAt: string
}

export const achievementNotificationDisplayText = (achievementNotification: string): string => {
  switch (achievementNotification) {
    case 'disabled':
      return '通知しない';
    case 'enabled':
      return '通知する';
    default:
      return '-';
  }
};
