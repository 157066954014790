import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid, Paper, TextField, Typography, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent, FormHelperText } from '@mui/material';
import { createMedium } from 'lib/api/admin/medium';
import { Medium } from 'lib/model/medium';
import { Client } from 'lib/model/client';
import { getClients } from 'lib/api/admin/client';

export default function MediaNew() {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedConnectionPlatformType, setSelectedConnectionPlatformType] = useState('not_connected');

  useEffect(() => {
    handleGetClients()
  }, [])

  const handleGetClients = async () => {
    try {
      const res = await getClients()
      if (res.status === 200) {
        setClients(res.data.clients)
      } else {
        console.log("No Clients")
      }
    } catch (err) {
      console.log(err)
    }
  };

  const history = useHistory();

  const onSubmit = async (data: Medium) => {
    await createMedium(data);
    history.push("/admin/media");
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>メディア新規登録</Typography>
      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <FormControl fullWidth error={errors.clientId ? true : false}>
                <InputLabel id="client_id_label">クライアント*</InputLabel>
                <Select
                  labelId="client_id_label"
                  id="client_id"
                  label="クライアント*"
                  {...register('clientId', {
                    required: "クライアントを選択してください",
                  })}
                  error={errors.clientId ? true : false}
                  value={selectedClientId}
                  onChange={(e: SelectChangeEvent<number>) => {
                    const value: number = e.target.value as number;
                    setSelectedClientId(value);
                    setValue('clientId', value);
                  }}
                >
                  {clients.map((client: Client) => (
                    <MenuItem value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.clientId ? <FormHelperText>{errors.clientId.message}</FormHelperText> : ""}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="name"
                label="メディア名*"
                fullWidth
                {...register('name', { required: "メディア名を入力してください" })}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="site_url"
                label="サイトURL*"
                fullWidth
                {...register('siteUrl', {
                  required: "サイトURLを入力してください",
                  pattern: {
                    value: /(http|https):\/\/(localhost|[\w-]+\.+[\w\-.]+)[\w./?#%+&=-]*/,
                    message: "サイトURLの形式が正しくありません"
                  }
                })}
                error={errors.siteUrl ? true : false}
                helperText={errors?.siteUrl?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="kickback_url"
                label="キックバックURL"
                fullWidth
                {...register('kickbackUrl', {
                  pattern: {
                    value: /(http|https):\/\/(localhost|[\w-]+\.+[\w\-.]+)[\w./?#%+&=-]*/,
                    message: "キックバックURLの形式が正しくありません"
                  }
                })}
                error={errors?.kickbackUrl?.message ? true : false}
                helperText={errors?.kickbackUrl?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <FormControl fullWidth>
                <InputLabel id="status_label">ステータス*</InputLabel>
                <Select
                  labelId="status_label"
                  id="status"
                  label="ステータス*"
                  {...register('status', {
                    required: "ステータスを選択してください",
                  })}
                  error={errors.status ? true : false}
                  value={selectedStatus}
                  onChange={(e: SelectChangeEvent<string>) => {
                    const value = e.target.value;
                    setSelectedStatus(value);
                    setValue('status', value);
                  }}
                >
                  <MenuItem value="">選択してください</MenuItem>
                  <MenuItem value="operating">稼働中</MenuItem>
                  <MenuItem value="stopped">停止中</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="remarks"
                label="備考"
                multiline
                rows={5}
                fullWidth
                {...register('remarks')}
                error={errors.remarks ? true : false}
                helperText={errors?.remarks?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <FormControl fullWidth>
                <InputLabel id="connection_platform_type_label">媒体連携</InputLabel>
                <Select
                  labelId="connection_platform_type_label"
                  id="connection_platform_type"
                  label="媒体連携"
                  {...register('connectionPlatformType')}
                  error={errors.connectionPlatformType ? true : false}
                  value={selectedConnectionPlatformType}
                  onChange={(e: SelectChangeEvent<string>) => {
                    const value = e.target.value;
                    setSelectedConnectionPlatformType(value);
                    setValue('connectionPlatformType', value);
                  }
                  }
                >
                  <MenuItem value="not_connected">未連携</MenuItem>
                  <MenuItem value="google_ads">Google広告</MenuItem>
                  <MenuItem value="yahoo_ads">Yahoo広告</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    component={Link}
                    to="/admin/media"
                    variant="outlined"
                    href="#outlined-buttons"
                  >
                    戻る
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    登録
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
