import { Client } from "./client";

export interface Medium {
  id: number;
  clientId: number;
  name: string;
  siteUrl: string | null;
  kickbackUrl: string | null;
  mediumCode: string;
  status: string;
  remarks: string | null;
  connectionPlatformType: string;
  googleAdsAccessToken: string;
  yahooAdsAccessToken: string;
  createdAt: string;
  updatedAt: string;

  client: Client;
};

export const mediumStatusDisplayText = (medium: Medium): string => {
  switch (medium.status) {
    case 'operating':
      return '稼働中';
    case 'stopped':
      return '停止中';
    default:
      return '-';
  }
};

export const connectionPlatformType = (medium: Medium): string => {
  switch (medium.connectionPlatformType) {
    case 'google_ads':
      return 'Google広告';
    case 'yahoo_ads':
      return 'Yahoo広告';
    default:
      return '未連携';
  }
};

export const adsAccessToken = (medium: Medium): string => {
  switch (medium.connectionPlatformType) {
    case 'google_ads':
      return medium.googleAdsAccessToken;
    case 'yahoo_ads':
      return medium.yahooAdsAccessToken;
    default:
      return '';
  }
};