import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

interface AdminDateAggregateReportSearchFormProps {
  onSearch: (query: any) => void;
}

export const AdminDateAggregateReportSearchForm: React.FC<AdminDateAggregateReportSearchFormProps> = ({ onSearch }) => {
  const [aggregateDateGteq, setAggregateDateGteq] = useState('');
  const [aggregateDateLteq, setAggregateDateLteq] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({
      aggregateDateGteq,
      aggregateDateLteq
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>集計期間を選択してください</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateGteq}
            onChange={(e) => setAggregateDateGteq(e.target.value)}
          />
        </Grid>

        <Grid item>~</Grid>

        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateLteq}
            onChange={(e) => setAggregateDateLteq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
