import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import applyCaseMiddleware from "axios-case-converter";

const options = {
  ignoreHeaders: true,
};

const phoneNumber: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

phoneNumber.interceptors.request.use(setHeaders);

export const getPhoneNumbers = async (query: {
  idEq?: string,
  clientIdEq?: string,
  callaIdEq?: string,
  phoneNumberEq?: string,
  remoteIpEq?: string,
  displayTimeGteq?: string,
  displayTimeLteq?: string,
  page?: number,
  pageSize?: number
}) => {
  return phoneNumber.get("/api/admin/phone_numbers", {
    params: {
      "q[id_eq]": query.idEq || null,
      "q[client_id_eq]": query.clientIdEq || null,
      "q[calla_id_eq]": query.callaIdEq || null,
      "q[phone_number_eq]": query.phoneNumberEq || null,
      "q[remote_ip_eq]": query.remoteIpEq || null,
      "q[display_time_gteq]": query.displayTimeGteq || null,
      "q[display_time_lteq]": query.displayTimeLteq || null,
      "page": query.page || 1,
      "page_size": query.pageSize || 30,
    }
  });
}
