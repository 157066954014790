import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getClients } from 'lib/api/admin/client';
import { Client } from 'lib/model/client';

interface AdminClientAggregateReportSearchFormProps {
  onSearch: (query: any) => void;
}

export const AdminClientAggregateReportSearchForm: React.FC<AdminClientAggregateReportSearchFormProps> = ({ onSearch }) => {
  const [clientIdEq, setClientIdEq] = useState('');
  const [clientNameStart, setClientNameStart] = useState('');
  const [clients, setClients] = useState<Client[]>([]);
  const [aggregateDateGteq, setAggregateDateGteq] = useState('');
  const [aggregateDateLteq, setAggregateDateLteq] = useState('');

  const handleClientSearch = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const res = await getClients({ nameStart: clientNameStart })
      if (res.status === 200) {
        setClients(res.data.clients)
      } else {
        console.log("No clients")
      }
    } catch (err) {
      console.log(err)
    }
  };

  const selectClient = (client: Client) => {
    setClientIdEq(`${client.id}`);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({
      clientIdEq,
      aggregateDateGteq,
      aggregateDateLteq
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>クライアント名でクライアントIDを検索できます</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="クライアントID"
            variant="outlined"
            size="small"
            value={clientIdEq}
            onChange={(e) => setClientIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="クライアント名(前方一致)"
            variant="outlined"
            size="small"
            value={clientNameStart}
            onChange={(e) => setClientNameStart(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={handleClientSearch}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mb: '10px' }}>
        <Grid item>
          <Paper elevation={3} style={{ overflow: 'auto', maxHeight: '416px' }}>
            <List style={{ width: '100%' }}>
              {clients.map((client: Client) => (
                <ListItem button key={client.id} onClick={() => selectClient(client)}>
                  {client.name}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>集計期間を選択してください</Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateGteq}
            onChange={(e) => setAggregateDateGteq(e.target.value)}
          />
        </Grid>

        <Grid item>~</Grid>

        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateLteq}
            onChange={(e) => setAggregateDateLteq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
