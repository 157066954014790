import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, useFieldArray } from "react-hook-form";
import { Box, Button, Grid, Paper, TextField, Typography, Select, MenuItem, InputLabel, FormControl, Alert, Card, CardActionArea, CardMedia, CardHeader, CardActions } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { AuthContext } from 'App';
import { getAdvertise, updateAdvertise } from 'lib/api/admin/advertise';
import { Advertise } from 'lib/model/advertise';
import { Client } from 'lib/model/client';

type AdvertisePartial = Pick<Advertise, "clientId" | "advertiseCode" | "name" | "cvTime" | "clickImage" | "clickImageUrl"> & {
  transferPhoneNumbersAttributes: {
    id?: number;
    phoneNumber: string;
    destroy?: boolean;
  }[];
  advertiseLandingPagesAttributes: {
    id?: number;
    landingPageCode: string;
    name: string;
    pcUrl: string;
    spUrl: string;
    destroy?: boolean;
  }[];
};

export default function AdvertisesEdit() {
  const { id } = useParams<{ id: string }>();
  const [advertise, setAdvertise] = useState<Advertise | undefined>();
  const [client, setClient] = useState<Client | undefined>();
  const [advertiseLandingPageVisibility, setAdvertiseLandingPageVisibility] = useState<boolean[]>([]);
  const [transferPhoneNumberVisibility, setTransferPhoneNumberVisibility] = useState<boolean[]>([]);
  const [deliveryClickImageUrlVisibility, setDeliveryClickImageUrlVisibility] = useState<boolean>(false);
  const [apiError, setApiError] = useState("");
  const [clickImage, setClickImage] = useState('');

  const { register, control, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm<AdvertisePartial>({
    defaultValues: {
      clientId: 0,
      advertiseCode: "",
      name: "",
      cvTime: 0,
      clickImage: "",
      clickImageUrl: "",
      advertiseLandingPagesAttributes: [{ id: 0, landingPageCode: "", name: "", pcUrl: "", spUrl: "", destroy: false }],
      transferPhoneNumbersAttributes: [{ id: 0, phoneNumber: "", destroy: false }]
    }
  });

  const { fields: transferPhoneNumberFields } = useFieldArray<AdvertisePartial, "transferPhoneNumbersAttributes">({
    control,
    name: "transferPhoneNumbersAttributes",
  });

  const { fields: advertiseLandingPageFields } = useFieldArray<AdvertisePartial, "advertiseLandingPagesAttributes">({
    control,
    name: "advertiseLandingPagesAttributes",
  });

  const history = useHistory()

  const onSubmit = async (data: Advertise) => {
    data.clickImage = clickImage;

    const apiResult = await updateAdvertise(id, data);
    if ('error' in apiResult) {
      setApiError(apiResult.error.join(", "));
    } else {
      history.push("/admin/advertises");
    }
  };

  useEffect(() => {
    handleGetAdvertise()
  }, [])

  const handleGetAdvertise = async () => {
    try {
      const res = await getAdvertise(id)

      if (res.status === 200) {
        setClient(res.data.client)
        setAdvertise(res.data)
        // 初期化時に表示ステートを設定
        setAdvertiseLandingPageVisibility(new Array(res.data.advertiseLandingPages.length).fill(true));
        setTransferPhoneNumberVisibility(new Array(res.data.transferPhoneNumbers.length).fill(true));
        setDeliveryClickImageUrlVisibility(res.data.clickImageUrl && res.data.clickImageUrl.length > 0);

        reset({
          clientId: res.data.clientId,
          advertiseCode: res.data.advertiseCode,
          name: res.data.name,
          cvTime: res.data.cvTime,
          clickImageUrl: res.data.clickImageUrl,
          advertiseLandingPagesAttributes: res.data.advertiseLandingPages,
          transferPhoneNumbersAttributes: res.data.transferPhoneNumbers
        });
      } else {
        console.log("No advertise")
      }
    } catch (err) {
      console.log(err)
    }
  };

  const handleAppendTransferPhoneNumber = () => {
    // 既存の transferPhoneNumbersAttributes の内容を保持します。
    const currentTransferPhoneNumbersAttributes = getValues("transferPhoneNumbersAttributes");

    // 新しい transferPhoneNumbersAttributes のリストを作成します。
    const newTransferPhoneNumbersAttributes = [
      ...currentTransferPhoneNumbersAttributes,
      { id: undefined, phoneNumber: "", destroy: false }
    ];

    // 新しいリストでフォームを更新します。
    setValue("transferPhoneNumbersAttributes", newTransferPhoneNumbersAttributes);
    setTransferPhoneNumberVisibility([...transferPhoneNumberVisibility, true]);
  };

  const handleRemoveTransferPhoneNumber = (index: number) => {
    setValue(`transferPhoneNumbersAttributes.${index}.destroy`, true);
    const newTransferPhoneNumberVisibility = [...transferPhoneNumberVisibility];
    newTransferPhoneNumberVisibility[index] = false;
    setTransferPhoneNumberVisibility(newTransferPhoneNumberVisibility);
  };

  const handleAppendAdvertiseLandingPage = () => {
    // 既存の advertiseLandingPages の内容を保持します。
    const currentAdvertiseLandingPagesAttributes = getValues("advertiseLandingPagesAttributes");

    // 新しい advertiseLandingPages のリストを作成します。
    const newAdvertiseLandingPagesAttributes = [
      ...currentAdvertiseLandingPagesAttributes,
      { id: undefined, landingPageCode: "", name: "", pcUrl: "", spUrl: "", destroy: false }
    ];

    // 新しいリストでフォームを更新します。
    setValue("advertiseLandingPagesAttributes", newAdvertiseLandingPagesAttributes);
    setAdvertiseLandingPageVisibility([...advertiseLandingPageVisibility, true]);
  };

  const handleRemoveAdvertiseLandingPage = (index: number) => {
    setValue(`advertiseLandingPagesAttributes.${index}.destroy`, true);
    const newAdvertiseLandingPageVisibility = [...advertiseLandingPageVisibility];
    newAdvertiseLandingPageVisibility[index] = false;
    setAdvertiseLandingPageVisibility(newAdvertiseLandingPageVisibility);
  };

  const hasDuplicatePhoneNumber = (
    value: string,
    index: number,
    getValues: () => any
  ) => {
    if (value === "") return true;
    const values = getValues();
    return (
      values.transferPhoneNumbersAttributes.findIndex(
        (item: any, i: number) =>
          isTransferPhoneNumberDisplayed(i) && item.phoneNumber === value && index !== i
      ) === -1
    );
  };

  const handleClickImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setClickImage(reader.result?.toString() || '');
      };
      reader.readAsDataURL(file);
    }
  };

  const isTransferPhoneNumberDisplayed = (index: number) => transferPhoneNumberVisibility[index];

  if (!advertise || !client) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>広告編集</Typography>
      {apiError && (
        <Alert severity="error" sx={{ mb: '10px' }}>
          {apiError}
        </Alert>
      )}

      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <FormControl>
                <InputLabel id="client_id_label">クライアント*</InputLabel>
                <Select
                  labelId="client_id_label"
                  id="client_id"
                  label="クライアント"
                  value={client.id}
                  inputProps={{ readOnly: true }}
                >
                  <MenuItem value={client.id}>{client.name}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="advertise_code"
                label="広告コード"
                fullWidth
                {...register('advertiseCode')}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="name"
                label="広告名*"
                fullWidth
                {...register('name', { required: "広告名を入力してください" })}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="cv_time"
                label="成果発生条件（秒）*"
                fullWidth
                {...register('cvTime', {
                  required: "成果発生条件（秒）を入力してください",
                  pattern: {
                    value: /\d+/,
                    message: "数値を入力してください"
                  }
                })}
                error={errors.cvTime ? true : false}
                helperText={errors?.cvTime?.message}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {advertiseLandingPageFields.map((field, index) => (
              advertiseLandingPageVisibility[index] && (
                <Grid key={field.id ?? index} item xs={12} md={12} lg={8}>
                  <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
                    <Grid container spacing={2} alignItems="center">

                      <Grid item xs={12}>
                        <Typography variant="h6">{`広告LP ${index + 1}`}</Typography>
                      </Grid>

                      <Grid item xs={12} md={12} lg={8}>
                        <TextField
                          label="広告LPコード"
                          fullWidth
                          {...register(`advertiseLandingPagesAttributes.${index}.landingPageCode`)}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label={`広告LP名 ${index + 1}*`}
                          fullWidth
                          {...register(`advertiseLandingPagesAttributes.${index}.name`, { required: "広告LP名を入力してください", })}
                          error={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index] ? true : false}
                          helperText={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index]?.name?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label={`PC用URL ${index + 1}*`}
                          fullWidth
                          {...register(`advertiseLandingPagesAttributes.${index}.pcUrl`, {
                            required: "PC用URLを入力してください",
                            pattern: {
                              value: /(http|https):\/\/(localhost|[\w-]+\.+[\w\-.]+)[\w./?#%+&=-]*/,
                              message: "PC用URLの形式が正しくありません"
                            }
                          })}
                          error={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index] ? true : false}
                          helperText={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index]?.pcUrl?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label={`SP用URL ${index + 1}*`}
                          fullWidth
                          {...register(`advertiseLandingPagesAttributes.${index}.spUrl`, {
                            required: "SP用URLを入力してください",
                            pattern: {
                              value: /(http|https):\/\/(localhost|[\w-]+\.+[\w\-.]+)[\w./?#%+&=-]*/,
                              message: "SP用URLの形式が正しくありません"
                            }
                          })}
                          error={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index] ? true : false}
                          helperText={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index]?.spUrl?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          onClick={() => handleRemoveAdvertiseLandingPage(index)}
                          variant="contained"
                          color="error"
                        >
                          削除
                        </Button>
                      </Grid>

                      <input
                        {...register(`advertiseLandingPagesAttributes.${index}.destroy`)}
                        type="hidden"
                      />
                    </Grid>
                  </Box>
                </Grid>
              )
            ))}

            <Grid item xs={12} md={12} lg={8}>
              <Button
                onClick={handleAppendAdvertiseLandingPage}
                variant="contained"
                color="primary"
              >
                広告LP追加
              </Button>
            </Grid>

            {transferPhoneNumberFields.map((field, index) => (
              transferPhoneNumberVisibility[index] && (
                <Grid key={field.id ?? index} item xs={12} md={12} lg={8}>
                  <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
                    <Grid container spacing={2} alignItems="center">

                      <Grid item xs={12}>
                        <Typography variant="h6">{`転送元電話番号 ${index + 1}`}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          key={field.id}
                          label={`計測番号 ${index + 1}*`}
                          fullWidth
                          {...register(`transferPhoneNumbersAttributes.${index}.phoneNumber`, {
                            required: index === 0 ? "最初の計測番号を入力してください" : "計測番号を入力してください",
                            pattern: {
                              value: /^(0\d{1,4}\d{1,4}\d{4})$/,
                              message: "計測番号の形式が正しくありません"
                            },
                            validate: (value) =>
                              hasDuplicatePhoneNumber(value, index, getValues) ||
                              "計測番号が重複しています"
                          })}
                          error={errors.transferPhoneNumbersAttributes && errors.transferPhoneNumbersAttributes[index] ? true : false}
                          helperText={errors.transferPhoneNumbersAttributes && errors.transferPhoneNumbersAttributes[index]?.phoneNumber?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          onClick={() => handleRemoveTransferPhoneNumber(index)}
                          variant="contained"
                          color="error"
                        >
                          削除
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            ))}

            <Grid item xs={12} md={12} lg={8}>
              <Button
                onClick={handleAppendTransferPhoneNumber}
                variant="contained"
                color="primary"
              >
                電話番号追加
              </Button>
            </Grid>

            <AuthContext.Consumer>
              {(resource) => (
                <TextField
                  id="client_id"
                  type="hidden"
                  value={resource.currentClient?.id}
                  {...register('clientId')}
                  InputLabelProps={{ shrink: true }}
                  style={visuallyHidden}
                />
              )}
            </AuthContext.Consumer>

            <Grid item xs={12} md={12} lg={8}>
              <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h6">ポップアップ画像</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      variant="outlined"
                      fullWidth
                      onChange={handleClickImageChange}
                    />
                  </Grid>

                  {clickImage && (
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader title="選択中のポップアップ画像" />
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height=""
                            image={clickImage.toString()}
                            alt="ポップアップ画像"
                            style={{
                              height: 300,
                              width: 'auto',
                              maxWidth: '100%',
                              objectFit: 'contain',
                              margin: 'auto',
                            }}
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )}

                  {deliveryClickImageUrlVisibility && (
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader title="配信中のポップアップ画像" />
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height=""
                            image={advertise.clickImageUrl}
                            alt="ポップアップ画像"
                            style={{
                              height: 300,
                              width: 'auto',
                              maxWidth: '100%',
                              objectFit: 'contain',
                              margin: 'auto',
                            }}
                          />
                        </CardActionArea>
                        <CardActions>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setValue('clickImageUrl', '');
                              setDeliveryClickImageUrlVisibility(false);
                            }}
                          >
                            削除
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={2}>
              <Button
                component={Link}
                to="/admin/advertises"
                variant="outlined"
                href="#outlined-buttons"
                sx={{ marginTop: '30px' }}
              >
                戻る
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '30px', marginLeft: '20px' }}
              >
                更新
              </Button>
            </Grid>
          </Grid>
        </form >
      </Paper >
    </>
  );
}
