import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid, Paper, TextField, Typography, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent, FormHelperText } from '@mui/material';

import { createClient } from 'lib/api/admin/client';
import { Client } from 'lib/model/client';

export default function CreateClient() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();

  const onSubmit = async (data: Client) => {
    await createClient(data);
    history.push('/admin/clients/');
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>
        クライアント新規作成
      </Typography>

      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="名前"
                fullWidth
                {...register('name', { required: "名前を入力してください" })}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="メール"
                fullWidth
                {...register('email', {
                  required: "メールを入力してください",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "メールの形式が正しくありません"
                  }
                })}
                error={errors.email ? true : false}
                helperText={errors?.email?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="パスワード"
                fullWidth
                type="password"
                {...register('password', {
                  required: "パスワードを入力してください",
                  minLength: {
                    value: 8,
                    message: "パスワードは8文字以上で入力してください"
                  },
                })}
                error={errors.password ? true : false}
                helperText={errors?.password?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                label="パスワード確認"
                fullWidth
                type="password"
                {...register('password_confirmation', {
                  required: "パスワード確認を入力してください",
                })}
                error={errors.password_confirmation ? true : false}
                helperText={errors?.password_confirmation?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <FormControl fullWidth error={errors.achievement_notification ? true : false}>
                <InputLabel id="achievement_notification_label">成果発生メール*</InputLabel>
                <Select
                  labelId="achievement_notification_label"
                  id="achievement_notification"
                  label="成果発生メール*"
                  defaultValue="disabled"
                  {...register('achievement_notification', {
                    required: "成果発生メールを選択してください",
                  })}
                  error={errors.achievement_notification ? true : false}
                >
                  <MenuItem value="disabled">通知しない</MenuItem>
                  <MenuItem value="enabled">通知する</MenuItem>
                </Select>
                {errors.achievement_notification ? <FormHelperText>{errors.achievement_notification.message}</FormHelperText> : ""}
              </FormControl>
            </Grid>

          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={2}>
              <Button
                component={Link}
                to="/admin/clients"
                variant="outlined"
                href="#outlined-buttons"
                sx={{ marginTop: '30px' }}
              >
                戻る
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '30px', marginLeft: '20px' }}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}