import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import applyCaseMiddleware from "axios-case-converter";
import { Advertise } from "lib/model/advertise";

const options = {
  ignoreHeaders: true,
};

const advertise: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_client_access_token");
  config.headers["client"] = Cookies.get("_client_client");
  config.headers["uid"] = Cookies.get("_client_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

advertise.interceptors.request.use(setHeaders);

export const getAdvertises = (query: {
  idEq?: string
  advertiseCodeEq?: string
  nameStart?: string
  page?: number
  pageSize?: number
} = {}) => {
  return advertise.get("/api/client/advertises", {
    params: {
      "q[id_eq]": query.idEq || null,
      "q[advertise_code_eq]": query.advertiseCodeEq || null,
      "q[name_start]": query.nameStart || null,
      page: query.page || 0,
      page_size: query.pageSize || 30,
    }
  });
};

export const getAdvertise = (id: string) => {
  return advertise.get(`/api/client/advertises/${id}`);
};

type CreateAdvertiseResult = AxiosResponse | { error: string[] };

export const createAdvertise = async (
  data: Partial<Advertise>
): Promise<CreateAdvertiseResult> => {
  try {
    return await advertise.post("/api/client/advertises", data);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return { error: error.response.data.errors };
    } else {
      return { error: ["Unexpected error occurred."] };
    }
  }
};

type UpdateAdvertiseResult = AxiosResponse | { error: string[] };

export const updateAdvertise = async (
  id: string,
  data: Advertise
): Promise<UpdateAdvertiseResult> => {
  try {
    return await advertise.put(`/api/client/advertises/${id}`, data);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return { error: error.response.data.errors };
    } else {
      return { error: ["Unexpected error occurred."] };
    }
  }
};
