import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { Box, Button, Grid, Paper, Select, MenuItem, TextField, Typography, Alert, InputLabel, FormControl, FormHelperText, SelectChangeEvent, Card, CardActionArea, CardMedia } from '@mui/material';
import { createAdvertise } from 'lib/api/admin/advertise';
import { Advertise } from 'lib/model/advertise';
import { AdvertiseLandingPage } from 'lib/model/advertiseLandingPage';
import { TransferPhoneNumber } from 'lib/model/transferPhoneNumber';
import { getClients } from 'lib/api/admin/client';
import { Client } from 'lib/model/client';

export default function AdvertisesNew() {
  const [apiError, setApiError] = useState("");
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const { setValue } = useForm<Advertise>();
  const [clickImage, setClickImage] = useState('');

  type FormData = {
    clientId: string;
    name: string;
    cvTime: number;
    kickbackUrl: string;
    clickImage: string;
    advertiseLandingPagesAttributes: AdvertiseLandingPage[];
    transferPhoneNumbersAttributes: TransferPhoneNumber[];
  };

  const { register, handleSubmit, control, formState: { errors }, getValues, } = useForm<FormData>({
    defaultValues: {
      advertiseLandingPagesAttributes: [{ name: "", pcUrl: "", spUrl: "" }],
      transferPhoneNumbersAttributes: [{ phoneNumber: "" }],
    },
  });

  const { fields: transferPhoneNumberFields, append: appendTransferPhoneNumber, remove: removeTransferPhoneNumber } = useFieldArray({
    control,
    name: "transferPhoneNumbersAttributes"
  });

  const { fields: advertiseLandingPageFields, append: appendAdvertiseLandingPage, remove: removeAdvertiseLandingPage } = useFieldArray({
    control,
    name: "advertiseLandingPagesAttributes"
  });

  const history = useHistory();

  const hasDuplicatePhoneNumber = (
    value: string,
    index: number,
    getValues: () => any
  ) => {
    if (value === "") return true;
    const values = getValues();
    return (
      values.transferPhoneNumbersAttributes.findIndex(
        (item: any, i: number) => item.phoneNumber === value && index !== i
      ) === -1
    );
  };

  const onSubmit = async (data: FormData) => {
    const formattedData: Partial<Advertise> = {
      clientId: parseInt(data.clientId),
      name: data.name,
      cvTime: data.cvTime,
      kickbackUrl: data.kickbackUrl,
      clickImage: clickImage,
      advertiseLandingPagesAttributes: data.advertiseLandingPagesAttributes,
      transferPhoneNumbersAttributes: data.transferPhoneNumbersAttributes,
    };

    const apiResult = await createAdvertise(formattedData);
    if ('error' in apiResult) {
      setApiError(apiResult.error.join(", "));
    } else {
      history.push("/admin/advertises");
    }
  };

  useEffect(() => {
    handleGetClients()
  }, [])

  const handleGetClients = async () => {
    try {
      const res = await getClients()
      if (res.status === 200) {
        setClients(res.data.clients)
      } else {
        console.log("No clients")
      }
    } catch (err) {
      console.log(err)
    }
  };

  if (!clients) {
    return <div>Loading...</div>;
  }

  const handleClickImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setClickImage(reader.result?.toString() || '');
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>広告登録</Typography>
      {apiError && (
        <Alert severity="error" sx={{ mb: '10px' }}>
          {apiError}
        </Alert>
      )}

      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <FormControl fullWidth error={errors.clientId ? true : false}>
                <InputLabel id="client_id_label">クライアント*</InputLabel>
                <Select
                  labelId="client_id_label"
                  id="client_id"
                  label="クライアント*"
                  {...register('clientId', {
                    required: "クライアントを選択してください",
                  })}
                  error={errors.clientId ? true : false}
                  value={selectedClientId}
                  onChange={(e: SelectChangeEvent<number>) => {
                    const value: number = e.target.value as number;
                    setSelectedClientId(value);
                    setValue('clientId', value);
                  }}
                >
                  {clients.map((client: Client) => (
                    <MenuItem value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.clientId ? <FormHelperText>{errors.clientId.message}</FormHelperText> : ""}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="name"
                label="広告名*"
                fullWidth
                {...register('name', { required: "広告名を入力してください" })}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="cv_time"
                label="成果発生条件（秒）*"
                fullWidth
                {...register('cvTime', {
                  required: "成果発生条件（秒）を入力してください",
                  pattern: {
                    value: /\d+/,
                    message: "数値を入力してください"
                  }
                })}
                error={errors.cvTime ? true : false}
                helperText={errors?.cvTime?.message}
              />
            </Grid>

            {advertiseLandingPageFields.map((item, index) => (
              <Grid key={item.id} item xs={12} md={12} lg={8}>
                <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h6">{`広告LP ${index + 1}`}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label={`広告LP名 ${index + 1}*`}
                        fullWidth
                        {...register(`advertiseLandingPagesAttributes.${index}.name`, { required: "広告LP名を入力してください" })}
                        error={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index] ? true : false}
                        helperText={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index]?.name?.message}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label={`PC用URL ${index + 1}*`}
                        fullWidth
                        {...register(`advertiseLandingPagesAttributes.${index}.pcUrl`, {
                          required: "PC用URLを入力してください",
                          pattern: {
                            value: /(http|https):\/\/(localhost|[\w-]+\.+[\w\-.]+)[\w./?#%+&=-]*/,
                            message: "PC用URLの形式が正しくありません"
                          }
                        })}
                        error={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index] ? true : false}
                        helperText={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index]?.pcUrl?.message}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label={`SP用URL ${index + 1}*`}
                        fullWidth
                        {...register(`advertiseLandingPagesAttributes.${index}.spUrl`, {
                          required: "SP用URLを入力してください",
                          pattern: {
                            value: /(http|https):\/\/(localhost|[\w-]+\.+[\w\-.]+)[\w./?#%+&=-]*/,
                            message: "SP用URLの形式が正しくありません"
                          }
                        })}
                        error={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index] ? true : false}
                        helperText={errors.advertiseLandingPagesAttributes && errors.advertiseLandingPagesAttributes[index]?.spUrl?.message}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          removeAdvertiseLandingPage(index);
                        }}
                        variant="contained"
                        color="error"
                      >
                        削除
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}

            <Grid item xs={12} md={12} lg={8}>
              <Button
                onClick={() => appendAdvertiseLandingPage({ pcUrl: "", spUrl: "" })}
                variant="contained"
                color="secondary"
              >
                広告LP追加
              </Button>
            </Grid>

            {transferPhoneNumberFields.map((item, index) => (
              <Grid key={item.id} item xs={12} md={12} lg={8}>
                <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
                  <Grid container spacing={2} alignItems="center">

                    <Grid item xs={12}>
                      <Typography variant="h6">{`転送元電話番号 ${index + 1}`}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label={`転送元電話番号 ${index + 1}*`}
                        fullWidth
                        {...register(`transferPhoneNumbersAttributes.${index}.phoneNumber`, {
                          required: "転送元電話番号を入力してください",
                          pattern: {
                            value: /^(0\d{1,4}\d{1,4}\d{4})$/,
                            message: "転送元電話番号の形式が正しくありません"
                          },
                          validate: (value) =>
                            hasDuplicatePhoneNumber(value, index, getValues) ||
                            "転送元電話番号が重複しています",
                        })}
                        error={errors.transferPhoneNumbersAttributes && errors.transferPhoneNumbersAttributes[index] ? true : false}
                        helperText={errors.transferPhoneNumbersAttributes && errors.transferPhoneNumbersAttributes[index]?.phoneNumber?.message}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          removeTransferPhoneNumber(index);
                        }}
                        variant="contained"
                        color="error"
                      >
                        削除
                      </Button>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
            ))}

            <Grid item xs={12} md={12} lg={8}>
              <Button
                onClick={() => appendTransferPhoneNumber({ phoneNumber: "" })}
                variant="contained"
                color="secondary"
              >
                電話番号追加
              </Button>
            </Grid>

            <Grid item xs={12} md={12} lg={8}>
              <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h6">ポップアップ画像</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      variant="outlined"
                      fullWidth
                      onChange={handleClickImageChange}
                    />
                  </Grid>

                  {clickImage && (
                    <Grid item xs={12}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height=""
                            image={clickImage.toString()}
                            alt="ポップアップ画像"
                            style={{
                              height: 300,
                              width: 'auto',
                              maxWidth: '100%',
                              objectFit: 'contain',
                              margin: 'auto',
                            }}
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={2}>
              <Button
                component={Link}
                to="/admin/advertises"
                variant="outlined"
                href="#outlined-buttons"
                sx={{ marginTop: '30px' }}
              >
                戻る
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '30px', marginLeft: '20px' }}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </form >
      </Paper >
    </>
  );
}
