import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getMedia } from 'lib/api/admin/medium';
import { Medium } from 'lib/model/medium';

interface AdminMediumAggregateReportSearchFormProps {
  onSearch: (query: any) => void;
}

export const AdminMediumAggregateReportSearchForm: React.FC<AdminMediumAggregateReportSearchFormProps> = ({ onSearch }) => {
  const [mediumIdEq, setMediumIdEq] = useState('');
  const [mediumNameStart, setMediumNameStart] = useState('');
  const [media, setMedia] = useState<Medium[]>([]);
  const [aggregateDateGteq, setAggregateDateGteq] = useState('');
  const [aggregateDateLteq, setAggregateDateLteq] = useState('');

  const handleMediumSearch = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const res = await getMedia({ nameStart: mediumNameStart })
      if (res.status === 200) {
        setMedia(res.data.media)
      } else {
        console.log("No media")
      }
    } catch (err) {
      console.log(err)
    }
  };

  const selectMedium = (medium: Medium) => {
    setMediumIdEq(`${medium.id}`);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch({
      mediumIdEq,
      aggregateDateGteq,
      aggregateDateLteq
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>メディア名でメディアIDを検索できます</Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            label="メディアID"
            variant="outlined"
            size="small"
            value={mediumIdEq}
            onChange={(e) => setMediumIdEq(e.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="メディア名(前方一致)"
            variant="outlined"
            size="small"
            value={mediumNameStart}
            onChange={(e) => setMediumNameStart(e.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={handleMediumSearch}>
            検索
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mb: '10px' }}>
        <Grid item>
          <Paper elevation={3} style={{ overflow: 'auto', maxHeight: '416px' }}>
            <List style={{ width: '100%' }}>
              {media.map((medium: Medium) => (
                <ListItem button key={medium.id} onClick={() => selectMedium(medium)}>
                  {medium.name}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>集計期間を選択してください</Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" sx={{ mb: '10px' }}>
        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateGteq}
            onChange={(e) => setAggregateDateGteq(e.target.value)}
          />
        </Grid>

        <Grid item>~</Grid>

        <Grid item>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            value={aggregateDateLteq}
            onChange={(e) => setAggregateDateLteq(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            検索
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
