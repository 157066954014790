import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Box, Paper, Typography, Divider, Grid } from '@mui/material';
import { Achievement } from 'lib/model/achievement';
import { getAchievement } from 'lib/api/admin/achievement';
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";

export default function AchievementsShow() {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();
  const [achievement, setAchievement] = useState<Achievement | null>(null);

  useEffect(() => {
    async function fetchAchievement() {
      try {
        const res = await getAchievement(parseInt(id, 10));
        if (res.status === 200) {
          setAchievement(res.data);
        } else {
          console.log('Could not fetch achievement details');
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchAchievement();
  }, [id]);

  if (!achievement) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4">成果詳細</Typography>
        <Button
          component={Link}
          to="/admin/achievements"
          variant="outlined"
          href="#outlined-buttons"
        >
          戻る
        </Button>
      </Box>
      <Paper
        sx={{
          padding: (theme) => theme.spacing(4),
          overflow: 'hidden',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>ID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>クライアントID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.client.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>クライアント名:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.client.name}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告ID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.advertise.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告名:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.advertise.name}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告LPID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.advertiseLandingPage?.id}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>広告LP名:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.advertiseLandingPage?.name}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メディアID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.medium?.id || ''}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>メディア名:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.medium?.name || ''}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>発信者電話番号:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.originatingNumber}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>転送元電話番号:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.forwardingNumber}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>転送先電話番号:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.destinationNumber}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>電話番号表示時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              {achievement.phoneNumberDisplayTime && format(Date.parse(achievement.phoneNumberDisplayTime), "yyyy/MM/dd HH:mm:ss")}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>通話発信時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom> {achievement.callStartTime ? format(new Date(achievement.callStartTime), 'yyyy/MM/dd HH:mm:ss') : ''}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>通話応答時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom> {achievement.callAnswerTime ? format(new Date(achievement.callAnswerTime), 'yyyy/MM/dd HH:mm:ss') : ''}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>通話終了時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom> {achievement.callEndTime ? format(new Date(achievement.callEndTime), 'yyyy/MM/dd HH:mm:ss') : ''}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>通話時間(秒):</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.callDuration}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>コールノートUUID:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.callNoteUuid}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>mid1:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.mid1}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>mid2:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.mid2}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>mid3:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.mid3}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>mid4:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.mid4}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>mid5:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.mid5}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>gclid:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.gclid}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>yclid:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.yclid}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>転送電話番号表示URL:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.displaySourceUrl}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>ユーザーエージェント:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.userAgent}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>デバイス:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{t(achievement.device)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>電話タイプ:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{t(`phone_type.${achievement.phoneType}`)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>リファラ:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.referer}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>パラメータ:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.parameters}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>ステータス:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{achievement.status === 'valid' ? '有効' : '無効'}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>キックバックステータス:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{t(`kickbackStatus.${achievement.kickbackStatus}`)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>成果発生メールステータス:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{t(`notificationStatus.${achievement.notificationStatus}`)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>作成時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{format(new Date(achievement.createdAt), 'yyyy-MM-dd HH:mm')}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: (theme) => theme.spacing(2, 0) }} />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>更新時刻:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>{format(new Date(achievement.updatedAt), 'yyyy-MM-dd HH:mm')}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
