import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import jaJson from 'components/locales/ja.json';
import { getCurrentClient } from 'lib/api/client/client';
import { getCurrentAdmin } from 'lib/api/admin/admin';
import { Client } from 'lib/model/client';
import { Admin } from 'lib/model/admin';
import ClientBaseLayout from 'components/layouts/client/BaseLayout';
import ClientSignIn from 'components/pages/client/sessions/NewPage';
import ClientHome from 'components/pages/client/home/IndexPage';
import ClientUserEditEmail from 'components/pages/client/users/EditEmailPage';
import ClientUserEditPassword from 'components/pages/client/users/EditPasswordPage';
import ClientAdvertises from 'components/pages/client/advertises/IndexPage';
import ClientAdvertisesShow from 'components/pages/client/advertises/ShowPage';
import ClientMedia from 'components/pages/client/media/IndexPage';
import ClientMediaShow from 'components/pages/client/media/ShowPage';
import ClientAchievements from 'components/pages/client/achievements/IndexPage';
import ClientAchievementsShow from 'components/pages/client/achievements/ShowPage';
import AdminBaseLayout from 'components/layouts/admin/BaseLayout';
import AdminSignIn from 'components/pages/admin/sessions/NewPage';
import AdminHome from 'components/pages/admin/home/IndexPage';
import AdminUserEditEmail from 'components/pages/admin/users/EditEmailPage';
import AdminUserEditPassword from 'components/pages/admin/users/EditPasswordPage';
import AdminMedia from 'components/pages/admin/media/IndexPage';
import AdminMediaNew from 'components/pages/admin/media/NewPage';
import AdminMediaEdit from 'components/pages/admin/media/EditPage';
import AdminMediaShow from 'components/pages/admin/media/ShowPage';
import AdminAchievements from 'components/pages/admin/achievements/IndexPage';
import AdminAchievementsShow from 'components/pages/admin/achievements/ShowPage';
import AdminAdvertises from 'components/pages/admin/advertises/IndexPage';
import AdminAdvertisesNew from 'components/pages/admin/advertises/NewPage';
import AdminAdvertiseShow from 'components/pages/admin/advertises/ShowPage';
import AdminAdvertisesEdit from 'components/pages/admin/advertises/EditPage';
import AdminClients from 'components/pages/admin/clients/IndexPage';
import AdminClientShow from 'components/pages/admin/clients/ShowPage';
import AdminClientsNew from 'components/pages/admin/clients/NewPage';
import AdminClientsEdit from 'components/pages/admin/clients/EditPage';
import AdminPhoneNumbers from 'components/pages/admin/phoneNumber/IndexPage';
import AdminClientAggregateReports from 'components/pages/admin/clientAggregateReports/IndexPage';
import AdminAdvertiseAggregateReports from 'components/pages/admin/advertiseAggregateReports/IndexPage';
import AdminMediumAggregateReports from 'components/pages/admin/mediumAggregateReports/IndexPage';
import AdminDateAggregateReports from 'components/pages/admin/dateAggregateReports/IndexPage';
import AdminRakeTasks from 'components/pages/admin/rakeTasks/IndexPage';
import Rollbar from 'rollbar';
import { Provider, ErrorBoundary } from '@rollbar/react';

export const AuthContext = createContext({} as {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  isSignedIn: boolean
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>
  currentClient: Client | undefined
  setCurrentClient: React.Dispatch<React.SetStateAction<Client | undefined>>
  currentAdmin: Admin | undefined
  setCurrentAdmin: React.Dispatch<React.SetStateAction<Admin | undefined>>
})

i18n.use(initReactI18next).init({
  resources: {
    ja: { translation: jaJson },
  },
  lng: 'ja',
  fallbackLng: 'ja',
});

export default function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<Client | undefined>();
  const [currentAdmin, setCurrentAdmin] = useState<Admin | undefined>();

  const rollbarConfig: Rollbar.Configuration = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: ['staging', 'production'].includes(process.env.NODE_ENV || ''),
    payload: {
      environment: process.env.NODE_ENV || '',
      client: {
        javascript: {
          code_version: '1.0.0',
          source_map_enabled: true,
        }
      }
    }
  };

  const handleGetCurrentClient = async () => {
    try {
      const res = await getCurrentClient()
      if (res.status === 200) {
        setIsSignedIn(true)
        setCurrentClient(res?.data.data)
      } else {
        console.log("No current user")
      }
    } catch (err) {
      console.log(err)
      setIsSignedIn(false)
    }
    setLoading(false)
  }

  const handleGetCurrentAdmin = async () => {
    try {
      const res = await getCurrentAdmin()
      if (res.status === 200) {
        setIsSignedIn(true)
        setCurrentAdmin(res?.data.data)
      } else {
        console.log("No current user")
      }
    } catch (err) {
      console.log(err)
      setIsSignedIn(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'admin') {
      handleGetCurrentAdmin()
    } else {
      handleGetCurrentClient()
    }
  }, [setCurrentAdmin, setCurrentClient])

  const ClientPrivateRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route {...rest} render={props => (
        !loading ? (
          isSignedIn
            ? <Component {...props} />
            : <Redirect to="/client/sign_in" />
        ) : (
          <></>
        ))
      } />
    );
  };

  const ClientPublicRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route {...rest} render={props => (
        !loading ? (
          isSignedIn
            ? <Redirect to="/client" />
            : <Component {...props} />
        ) : (
          <></>
        ))
      } />
    );
  };

  const AdminPrivateRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route {...rest} render={props => (
        !loading ? (
          isSignedIn
            ? <Component {...props} />
            : <Redirect to="/admin/sign_in" />
        ) : (
          <></>
        ))
      } />
    );
  };

  const AdminPublicRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route {...rest} render={props => (
        !loading ? (
          isSignedIn
            ? <Redirect to="/admin" />
            : <Component {...props} />
        ) : (
          <></>
        ))
      } />
    );
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Router>
          <AuthContext.Provider value={{ loading, setLoading, isSignedIn, setIsSignedIn, currentClient, setCurrentClient, currentAdmin, setCurrentAdmin }}>
            <Route exact path="/">
              <Redirect to="/client" />
            </Route>
            <Route path="/client">
              <ClientBaseLayout>
                <Switch>
                  <ClientPublicRoute exact path="/" component={ClientSignIn} />
                  <ClientPublicRoute exact path="/client/sign_in" component={ClientSignIn} />
                  <ClientPrivateRoute exact path="/client" component={ClientHome} />
                  <ClientPrivateRoute exact path="/client/user/email/edit" component={ClientUserEditEmail} />
                  <ClientPrivateRoute exact path="/client/user/password/edit" component={ClientUserEditPassword} />
                  <ClientPrivateRoute exact path="/client/advertises" component={ClientAdvertises} />
                  <ClientPrivateRoute exact path="/client/advertises/:id" component={ClientAdvertisesShow} />
                  <ClientPrivateRoute exact path="/client/media" component={ClientMedia} />
                  <ClientPrivateRoute exact path="/client/media/:id" component={ClientMediaShow} />
                  <ClientPrivateRoute exact path="/client/achievements" component={ClientAchievements} />
                  <ClientPrivateRoute exact path="/client/achievements/:id" component={ClientAchievementsShow} />
                </Switch>
              </ClientBaseLayout>
            </Route>
            <Route path="/admin">
              <AdminBaseLayout>
                <Switch>
                  <AdminPublicRoute exact path="/admin/sign_in" component={AdminSignIn} />
                  <AdminPrivateRoute exact path="/admin" component={AdminHome} />
                  <ClientPrivateRoute exact path="/admin/user/email/edit" component={AdminUserEditEmail} />
                  <ClientPrivateRoute exact path="/admin/user/password/edit" component={AdminUserEditPassword} />
                  <AdminPrivateRoute exact path="/admin/media" component={AdminMedia} />
                  <AdminPrivateRoute exact path="/admin/media/new" component={AdminMediaNew} />
                  <AdminPrivateRoute exact path="/admin/media/:id/edit" component={AdminMediaEdit} />
                  <AdminPrivateRoute exact path="/admin/media/:id" component={AdminMediaShow} />
                  <AdminPrivateRoute exact path="/admin/achievements" component={AdminAchievements} />
                  <AdminPrivateRoute exact path="/admin/achievements/:id" component={AdminAchievementsShow} />
                  <AdminPrivateRoute exact path="/admin/advertises" component={AdminAdvertises} />
                  <AdminPrivateRoute exact path="/admin/advertises/new" component={AdminAdvertisesNew} />
                  <AdminPrivateRoute exact path="/admin/advertises/:id" component={AdminAdvertiseShow} />
                  <AdminPrivateRoute exact path="/admin/advertises/:id/edit" component={AdminAdvertisesEdit} />
                  <AdminPrivateRoute exact path="/admin/clients" component={AdminClients} />
                  <AdminPrivateRoute exact path="/admin/clients/new" component={AdminClientsNew} />
                  <AdminPrivateRoute exact path="/admin/clients/:id" component={AdminClientShow} />
                  <AdminPrivateRoute exact path="/admin/clients/:id/edit" component={AdminClientsEdit} />
                  <AdminPrivateRoute exact path="/admin/phone-numbers" component={AdminPhoneNumbers} />
                  <AdminPrivateRoute exact path="/admin/client-aggregate-reports" component={AdminClientAggregateReports} />
                  <AdminPrivateRoute exact path="/admin/advertise-aggregate-reports" component={AdminAdvertiseAggregateReports} />
                  <AdminPrivateRoute exact path="/admin/medium-aggregate-reports" component={AdminMediumAggregateReports} />
                  <AdminPrivateRoute exact path="/admin/date-aggregate-reports" component={AdminDateAggregateReports} />
                  <AdminPrivateRoute exact path="/admin/rake-tasks" component={AdminRakeTasks} />
                </Switch>
              </AdminBaseLayout>
            </Route>
          </AuthContext.Provider>
        </Router >
      </ErrorBoundary>
    </Provider >
  );
};
