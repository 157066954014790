
import { useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Paper, Grid, TextField, Typography } from '@mui/material';
import { AuthContext } from 'App';
import AlertMessage from 'components/utils/AlertMessage';
import { Client } from 'lib/model/client';
import { updateCurrentClient, signOut } from 'lib/api/client/client';

export default function ClientUserEditPassword() {
  const { register, handleSubmit, getValues, formState: {errors} } = useForm();
  const { setIsSignedIn } = useContext(AuthContext);
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false);

  const userSignOut = () => {
    signOut()
    Cookies.remove("_client_access_token")
    Cookies.remove("_client_client")
    Cookies.remove("_client_uid")
    setIsSignedIn(false)
    history.push("/client/sign_in", { message: "パスワードを変更しました。再度ログインしてください" });
  };

  const history = useHistory()
  // 更新処理
  const onSubmit = async (data: Client) => {
    try {
      const res = await updateCurrentClient(data);
      if (res.status === 200) {
        console.log('Client password updated successfully');
        userSignOut()
      } else {
        console.log('Failed to update client password');
      }
    } catch (err) {
      console.log(err);
      setAlertMessageOpen(true)
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>パスワード編集</Typography>
      <Paper sx={{ padding: '40px', overflow: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={8}>
              <AlertMessage
                open={alertMessageOpen}
                setOpen={setAlertMessageOpen}
                severity="error"
                message="パスワードの変更に失敗しました"
              />
              <TextField
                id="password"
                label="新しいパスワード*"
                type="password"
                fullWidth
                {...register('password', {
                  required: "新しいパスワードを入力してください",
                  minLength: {
                    value: 8,
                    message: "半角英数8文字以上で入力してください"
                  }
                })}
                error={errors.password ? true : false}
                helperText={errors?.password?.message}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <TextField
                id="password_confirmation"
                label="パスワード（確認）*"
                type="password"
                fullWidth
                {...register('passwordConfirmation', {
                  required: "パスワード（確認）を入力してください",
                  minLength: {
                    value: 8,
                    message: "半角英数8文字以上で入力してください"
                  },
                  validate: {
                    matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || "パスワード（確認）が異なります";
                  }},
                })}
                error={errors.passwordConfirmation ? true : false}
                helperText={errors?.passwordConfirmation?.message}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '30px', marginLeft: '20px' }}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
