import { forwardRef } from 'react';
import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface AlertMessageProps {
  open: boolean
  severity: "error" | "success"
  message: string
  setOpen: Function
}

const InnerAlert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = ({ open, setOpen, severity, message}: AlertMessageProps) => {
  const handleCloseAlertMessage = (e?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return
  
    setOpen(false)
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={(e) => handleCloseAlertMessage}
    >
      <InnerAlert
        onClose={(e) => handleCloseAlertMessage}
        severity={severity}
        sx={{ width: "500px" }}
      >
        {message}
      </InnerAlert>
    </Snackbar>
  )
}

export default AlertMessage;
