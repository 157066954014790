import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, jaJP, } from '@mui/x-data-grid';
import { getAdvertises } from 'lib/api/client/advertise';
import { Advertise } from 'lib/model/advertise';
import { AdvertiseLandingPage } from 'lib/model/advertiseLandingPage';
import { ClientAdvertiseSearchForm } from 'components/search/ClientAdvertiseSearchForm';

export default function Advertises() {
  const [loading, setLoading] = useState<boolean>(true);
  const [advertises, setAdvertises] = useState<Advertise[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    handleGetAdvertises()
  }, [paginationModel, searchQuery]);

  const onSearch = (query = {}) => {
    setSearchQuery(query);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const history = useHistory();

  const handleDetailButtonClick = (id: number) => {
    history.push(`/client/advertises/${id}`);
  };

  const handleGetAdvertises = async () => {
    setLoading(true);

    const paginatedQuery = {
      ...searchQuery,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    };

    try {
      const res = await getAdvertises(paginatedQuery)
      if (res.status === 200) {
        setAdvertises(res.data.advertises)
        setTotalRowCount(res.data.pagy.count);
      } else {
        console.log("No advertises")
      }
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "広告ID", flex: 0.5, minWidth: 100 },
    { field: "advertiseCode", headerName: "広告コード", flex: 0.5, minWidth: 200 },
    { field: "advertiseName", headerName: "広告名", flex: 0.5, minWidth: 200 },
    {
      field: "clickUrl",
      headerName: "広告掲載用URL",
      flex: 1,
      minWidth: 600,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div>
          {
            params.row.advertise.advertiseLandingPages?.map((advertiseLandingPage: AdvertiseLandingPage, index: number) => (
              <>
                <a href={`${process.env.REACT_APP_API_URL}/click?acode=${params.row.advertise.advertiseCode}&lcode=${advertiseLandingPage.landingPageCode}`}>
                  {`${process.env.REACT_APP_API_URL}/click?acode=${params.row.advertise.advertiseCode}&lcode=${advertiseLandingPage.landingPageCode}`}
                </a>
                <br />
              </>
            ))
          } </div>
      )
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          variant="contained"
          size="small"
          color="inherit"
          onClick={() => handleDetailButtonClick(Number(params.row.advertise.id))}
        >
          詳細
        </Button>
      ),
    },
  ]

  const rows =
    !loading && advertises.length > 0
      ? advertises.map((advertise: Advertise) => ({
        id: advertise.id,
        advertiseCode: advertise.advertiseCode,
        advertiseName: advertise.name,
        advertise: advertise,
      }))
      : [];

  return (
    <>
      <Typography variant="h4" sx={{ mb: '10px' }}>広告一覧</Typography>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <ClientAdvertiseSearchForm onSearch={onSearch} />
      </Paper>

      <Paper elevation={3} sx={{ width: '100%' }}>
        <Box sx={{ height: rows.length > 0 ? 'auto' : '300px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRowCount}
            loading={loading}
            pageSizeOptions={[30, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Paper>
    </>
  );
}
