import { useHistory } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PeopleIcon from '@mui/icons-material/People';
import WebIcon from '@mui/icons-material/Web';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArticleIcon from '@mui/icons-material/Article';
import PaymentsIcon from '@mui/icons-material/Payments';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AddTaskIcon from '@mui/icons-material/AddTask';

const MenuListItems = () => {
  const history = useHistory();

  return (
    <>
      <ListSubheader component="div">
        管理
      </ListSubheader>

      <ListItemButton onClick={() => history.push("/admin/clients")}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="クライアント" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/admin/advertises")}>
        <ListItemIcon>
          <WebIcon />
        </ListItemIcon>
        <ListItemText primary="広告" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/admin/media")}>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="メディア" />
      </ListItemButton>

      <ListSubheader component="div">
        計測
      </ListSubheader>

      <ListItemButton onClick={() => history.push("/admin/achievements")}>
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="成果" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/admin/phone-numbers")}>
        <ListItemIcon>
          <PhoneInTalkIcon />
        </ListItemIcon>
        <ListItemText primary="電話番号表示" />
      </ListItemButton>

      <ListSubheader component="div">
        レポート
      </ListSubheader>

      <ListItemButton onClick={() => history.push("/admin/client-aggregate-reports")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="クライアント別" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/admin/advertise-aggregate-reports")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="広告別" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/admin/medium-aggregate-reports")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="メディア別" />
      </ListItemButton>

      <ListItemButton onClick={() => history.push("/admin/date-aggregate-reports")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="日別" />
      </ListItemButton>

      <ListSubheader component="div">
        その他
      </ListSubheader>

      <ListItemButton onClick={() => history.push("/admin/rake-tasks")}>
        <ListItemIcon>
          <AddTaskIcon />
        </ListItemIcon>
        <ListItemText primary="Rakeタスク" />
      </ListItemButton>
    </>
  )
}

const Sidebar = () => {
  return (
    <List component="nav">
      <MenuListItems />
    </List>
  );
};

export default Sidebar;
