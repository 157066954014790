import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import applyCaseMiddleware from "axios-case-converter";

const options = {
  ignoreHeaders: true,
};

const rakeTask: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

rakeTask.interceptors.request.use(setHeaders);

export const executeRakeTask = async (taskName: string, args: any) => {
  try {
    const res = await rakeTask.post('/api/admin/rake_tasks', {
      taskName: taskName,
      args: args
    });

    if (res.status === 200) {
      console.log('Task executed successfully');
    } else {
      console.log('Failed to execute task');
      throw new Error("Failed to execute task");
    }
  } catch (err) {
    console.log('Failed to execute task');
    throw new Error("Failed to execute task");
  }
}
