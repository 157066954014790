import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import applyCaseMiddleware from "axios-case-converter";
import { Medium as MediumModel } from "lib/model/medium";

const options = {
  ignoreHeaders: true,
};

const media: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  options
);

const setHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers["access-token"] = Cookies.get("_admin_access_token");
  config.headers["client"] = Cookies.get("_admin_client");
  config.headers["uid"] = Cookies.get("_admin_uid");
  config.headers["Content-Type"] = "application/json";
  return config;
};

media.interceptors.request.use(setHeaders);

export const getMedia = (query: {
  idEq?: string
  mediumCodeEq?: string
  nameStart?: string
  page?: number
  pageSize?: number
} = {}) => {
  return media.get("/api/admin/media", {
    params: {
      "q[id_eq]": query.idEq || null,
      "q[name_start]": query.nameStart || null,
      "q[medium_code_eq]": query.mediumCodeEq || null,
      "page": query.page || 1,
      "page_size": query.pageSize || 30,
    }
  });
};

export const getMedium = async (id: string) => {
  return await media.get(`/api/admin/media/${id}`);
};

export const createMedium = (data: MediumModel) => {
  return media.post("/api/admin/media", data);
};

export const updateMedium = (id: string, data: MediumModel) => {
  return media.put(`/api/admin/media/${id}`, data);
};
