import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, jaJP, } from '@mui/x-data-grid';
import { getMedia } from 'lib/api/client/medium';
import { Medium, mediumStatusDisplayText } from 'lib/model/medium';
import { AdminMediumSearchForm } from 'components/search/AdminMediumSearchForm';

export default function Media() {
  const [loading, setLoading] = useState<boolean>(true);
  const [media, setMedia] = useState<Medium[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    handleGetMedia()
  }, [paginationModel, searchQuery]);

  const onSearch = (query = {}) => {
    setSearchQuery(query);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const history = useHistory();

  const handleDetailButtonClick = (id: number) => {
    history.push(`/client/media/${id}`);
  };

  const handleGetMedia = async () => {
    setLoading(true);

    const paginatedQuery = {
      ...searchQuery,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    };

    try {
      const res = await getMedia(paginatedQuery);
      if (res.status === 200) {
        setMedia(res.data.media);
        setTotalRowCount(res.data.pagy.count);
      } else {
        console.log("No Media");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "メディアID", flex: 1, minWidth: 100 },
    { field: "mediumCode", headerName: "メディアコード", flex: 1, minWidth: 100 },
    { field: "name", headerName: "メディア名", flex: 1, minWidth: 200 },
    { field: "siteUrl", headerName: "メディアURL", flex: 1, minWidth: 300 },
    { field: "status", headerName: "ステータス", flex: 1, minWidth: 100 },
    {
      field: "actions",
      type: "actions",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box display="flex" gap="10px">
          <Button
            variant="contained"
            size="small"
            color="inherit"
            onClick={() => handleDetailButtonClick(Number(params.row.medium.id))}
          >
            詳細
          </Button>
        </Box>
      ),
    },
  ]

  const rows =
    !loading && media.length > 0
      ? media.map((medium: Medium) => ({
        id: medium.id,
        mediumCode: medium.mediumCode,
        name: medium.name,
        siteUrl: medium.siteUrl,
        status: mediumStatusDisplayText(medium),
        medium: medium,
      }))
      : [];

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ mb: '10px' }}>メディア一覧</Typography>
      </Box>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <AdminMediumSearchForm onSearch={onSearch} />
      </Paper>

      <Paper elevation={3} sx={{ width: '100%' }}>
        <Box sx={{ height: rows.length > 0 ? 'auto' : '300px', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRowCount}
            loading={loading}
            pageSizeOptions={[30, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Paper>
    </>
  );
}
