import { useState } from 'react';
import { Typography, Paper, Box, Button, Snackbar } from '@mui/material';
import { executeRakeTask } from 'lib/api/admin/rakeTask';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ja } from 'date-fns/locale';
import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';


export default function RakeTasks() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isExecuting, setIsExecuting] = useState(false);
  const [taskName, setTaskName] = useState('');
  const [args, setArgs] = useState('');
  const [date, setDate] = useState('');

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={message}
      />

      <Typography variant="h4" gutterBottom>Rakeタスク一覧</Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Rakeタスク名</TableCell>
              <TableCell>タスク</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">Rakeタスク</TableCell>
              <TableCell>コマンド</TableCell>
              <TableCell>
                <TextField
                  id="taskName"
                  label="Rakeコマンド"
                  fullWidth
                  onChange={(e) => {
                    const value = e.target.value;
                    setTaskName(value);
                  }}
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  id="args"
                  label="引数"
                  value={args}
                  fullWidth
                  onChange={(e) => {
                    const value = e.target.value;
                    setArgs(value);
                  }} />
              </TableCell>
              <TableCell align="right">
                <Button variant="contained" color="primary" disabled={isExecuting} onClick={async () => {
                  setIsExecuting(true);
                  try {
                    await executeRakeTask(taskName, args);
                    setMessage('タスクの実行に成功しました 完了までお待ちください');
                    setOpen(true);
                  } catch (error) {
                    setMessage('タスクの実行に失敗しました');
                    setOpen(true);
                  } finally {
                    setIsExecuting(false);
                  }
                }}
                >
                  実行
                </Button>
              </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">電話番号集計</TableCell>
              <TableCell>call_tracking:aggregate_phone_number</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DatePicker format="yyyy-MM-dd" onAccept={(selectedDate: Date | null) => {
                    if (selectedDate) {
                      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
                      setDate(formattedDate);
                    }
                  }} />
                </LocalizationProvider>
              </TableCell>
              <TableCell align="right">
                <Button variant="contained" color="primary" disabled={isExecuting} onClick={async () => {
                  setIsExecuting(true);
                  try {
                    await executeRakeTask('call_tracking:aggregate_phone_number', date);
                    setMessage('タスクの実行に成功しました 完了までお待ちください');
                    setOpen(true);
                  } catch (error) {
                    setMessage('タスクの実行に失敗しました');
                    setOpen(true);
                  } finally {
                    setIsExecuting(false);
                  }
                }}
                >
                  実行
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}